import React from "react";
import Navbarpages from "../navbar-pages";
import { Link } from "react-router-dom";
import apiSimulador from "../../services/simuladorApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import urlGlobal from "../../services/urlGlobal";

class LayoutSimuladorCredito extends React.Component {

    state = {
        segmentointerno: [],
        subcalificacion: [],
        form: {
            'tipocredito': '',
            'segmentointerno': '',
            'subcalificacion': '',
            'monto': '',
            'diafijo': '',
            'cuotas': '',
        }
    }

    limpiar = () => {
        this.setState({
            form: {
                'tipocredito': '',
                'segmentointerno': '',
                'subcalificacion': '',
                'monto': '',
                'diafijo': '',
                'cuotas': '',
            },
            loading: 'false',
            tablapresuntiva: [],
            resultcredito: {
                FechaAdjudicacion: '',
                FechaVencimiento: '',
                Tea: '',
                ValorTasa: '',
                DescripcionCondicionTablaAmortizacion: '',
                DescripcionTipoPrestamo: ''
            },
            calculo: 'false',
            subcalificacion: []
        })
    }

    handleChange = (e) => {
        if (e.target.name === 'tipocredito') {
            this.getSegmentointerno(e.target.value);
        }
        if (e.target.name === 'segmentointerno') {
            this.getSubcalificacion(e.target.value);
        }
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    getSegmentointerno = async (coditoTipoCredito) => {
        try {
            const data = await apiSimulador.simulador.segmentointerno({ codigo: coditoTipoCredito });
            this.setState({ segmentointerno: data.segmentointernos });
        } catch (error) {
            console.log(error);
        }
    }

    getSubcalificacion = async (secuencial) => {
        try {
            const data = await apiSimulador.simulador.subcalificacion(secuencial);
            this.setState({ subcalificacion: data.subcalificacion });
        } catch (error) {
            console.log(error);
        }
    }

    consultarCredito = async () => {
        if (this.state.form.segmentointerno === '' || this.state.form.subcalificacion === '' || this.state.form.monto === '' || this.state.form.diafijo === '' || this.state.form.cuotas === '') {
            toast.error("Tienes que llenar todos los campos");
        } else {
            this.setState({ loading: 'true' });
            try {
                const data = await apiSimulador.simulador.consultarCredito(this.state.form.tipocredito, '1', this.state.form.segmentointerno, this.state.form.subcalificacion, urlGlobal.fechaActual(), this.state.form.monto, this.state.form.diafijo, this.state.form.cuotas, 30);
                this.setState(
                    {
                        loading: 'false',
                        tablapresuntiva: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla.TablaPagos_PorCuotas,
                        resultcredito: {
                            FechaAdjudicacion: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.FechaAdjudicacion,
                            FechaVencimiento: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.FechaVencimiento,
                            Tea: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.Tea,
                            ValorTasa: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ValorTasa,
                            DescripcionCondicionTablaAmortizacion: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.DescripcionCondicionTablaAmortizacion,
                            DescripcionTipoPrestamo: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.DescripcionTipoPrestamo
                        },
                        calculo: 'true'
                    });
            } catch (error) {
                console.log(error);
                this.setState({ loading: 'false' });
            }
        }
    }

    mascarafecha = (fecha) => {
        var event = new Date(fecha);
        let date = JSON.stringify(event)
        date = date.slice(1, 11)
        return date;
    }

    mostrarCalculo = () => {
        if (this.state.calculo === 'true') {
            return (
                <>

                    <div className="bg-light p-2 rounded">
                        <br />
                        <div className="text-center border-bottom">
                            <h4>TABLA PRESUNTIVA DE PAGOS</h4>
                        </div>
                        <br />
                        <div className="row font-letter">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Tipo de prestamo:</b> </div>
                                    <div className="col-lg-6"> {this.state.resultcredito.DescripcionTipoPrestamo} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>No. cuotas:</b> </div>
                                    <div className="col-lg-6"> {this.state.form.cuotas} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Frecuencia de pago:</b> </div>
                                    <div className="col-lg-6"> 30 </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Monto solicitado:</b> </div>
                                    <div className="col-lg-6"> ${this.state.form.monto} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Valor Tasa:</b> </div>
                                    <div className="col-lg-6"> ${this.state.resultcredito.ValorTasa} </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Fecha de adjudicación:</b> </div>
                                    <div className="col-lg-6"> {this.mascarafecha(this.state.resultcredito.FechaAdjudicacion)} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Fecha de vencimiento:</b> </div>
                                    <div className="col-lg-6"> {this.mascarafecha(this.state.resultcredito.FechaVencimiento)} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Deuda inicial:</b> </div>
                                    <div className="col-lg-6"> ${this.state.form.monto} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end text-secondary"> <b>Valor a entregar:</b> </div>
                                    <div className="col-lg-6"> ${this.state.form.monto}</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr className="text-secondary">
                                        <th scope="col">Cuota</th>
                                        <th scope="col">Fecha de pago</th>
                                        <th scope="col">Capital</th>
                                        <th scope="col">Interés</th>
                                        <th scope="col">Otros</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Saldo capital</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.tablapresuntiva.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.NumeroCuota}</td>
                                                    <td>{this.mascarafecha(data.FechaPago)}</td>
                                                    <td>${data.ValorCapital}</td>
                                                    <td>${data.ValorInteres}</td>
                                                    <td>${data.ValorOtros}</td>
                                                    <td>${data.ValorTotal}</td>
                                                    <td>${data.SaldoCapital}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                </>
            )
        } else {

        }

    }

    loadingShow = () => {
        if (this.state.loading === 'true') {
            return (
                <>
                    <div className="d-flex align-items-center">
                        <strong>Cargando...</strong>
                        <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <Navbarpages />

                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/'}>Inicio</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Simulador de crédito</li>
                        </ol>
                    </nav>
                    <h5 className="pb-3 border-bottom border-primary text-primary mb-3">
                        <b>SIMULADOR DE CRÉDITO</b>
                    </h5>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-floating">
                                    <select className="form-select" name="tipocredito" onChange={this.handleChange} value={this.state.form.tipocredito} required>
                                        <option value="">...</option>
                                        <option value="015">Microcredito</option>
                                        <option value="014">Consumo</option>
                                        <option value="010">Inmobiliario</option>
                                    </select>
                                    <label htmlFor="floatingSelect">Tipo de prestamo</label>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-floating">
                                    <select className="form-select" name="segmentointerno" onChange={this.handleChange} value={this.state.form.segmentointerno} required>
                                        <option value="" >
                                            ...
                                        </option>
                                        {
                                            this.state.segmentointerno.map((data, index) => {
                                                return (
                                                    <option value={data.secuencial} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="floatingSelect">Segmento interno</label>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-floating">
                                    <select className="form-select" onChange={this.handleChange} name="subcalificacion" value={this.state.form.subcalificacion} required>
                                        <option value="" >
                                            ...
                                        </option>
                                        {
                                            this.state.subcalificacion.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="floatingSelect">Subcalificación</label>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-floating mb-3">
                                    <input className="form-control" type="number" onChange={this.handleChange} name="monto" value={this.state.form.monto} required />
                                    <label htmlFor="floatingInput">Monto</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-floating mb-3">
                                    <input className="form-control" type="number" onChange={this.handleChange} name="diafijo" value={this.state.form.diafijo} required />
                                    <label htmlFor="floatingInput">Día fijo</label>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-floating mb-3">
                                    <input className="form-control" type="number" onChange={this.handleChange} name="cuotas" value={this.state.form.cuotas} required />
                                    <label htmlFor="floatingInput">Número de cuotas</label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="text-end">
                            <button type="button" className="btn btn-outline-danger me-3" onClick={() => this.limpiar()}>Limpiar</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.consultarCredito()}>Calcular</button>
                        </div>
                    </div>
                    <br />

                    <div className="text-center">
                        {this.loadingShow()}
                    </div>

                    {this.mostrarCalculo()}
                    <br />
                    <br />
                    <br />
                </div>
            </React.Fragment>
        )
    }
}

export default LayoutSimuladorCredito;