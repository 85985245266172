import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "../../withRouter";
import Navbarpages from "../navbar-pages";
import 'react-responsive-modal/styles.css';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Forms from "./forms";
import '../../App.css';
import SweetAlert2 from "react-sweetalert2";

class LayoutAgregarSolicitud extends React.Component {

    constructor() {
        super()
    }

    state = {
        formpersona: {
            'tipoidentificacion': '',
            'identificacion': '',
            'nombres': '',
            'celular': '',
            'email': '',
            'fechanacimiento': '',
            'telefono': '',
            'idprovincia': 0,
            'idciudad': 0,
            'direccion': '',
            'essocio': false,
            'tipovivienda': '',
            'estadocivil': '',
            'cargasfamilares': ''
        },
    }

    componentDidMount() {
        if (sessionStorage.getItem('identificacion') == '' || sessionStorage.getItem('identificacion') == null) {
            this.props.navigate('/')
        } else {
            this.setState({
                formpersona: {
                    ... this.state.formpersona,
                    identificacion: sessionStorage.getItem('identificacion'),
                    tipoidentificacion: sessionStorage.getItem('tipoidentificacion'),
                }
            });
            //this.verificarSiEsSocio();
            //this.getPersonaExistente();
        }
    }

    formatDates = (date) => {
        return date.split("T")[0]
    }

    irHaSolicitud = () => {
        setTimeout(() => {
            this.props.navigate('/consultar-solicitud')
        }, 1000);
    }

    render() {
        return (
            <React.Fragment>
                <SweetAlert2 {...this.state.swal} />
                <ToastContainer />
                <Navbarpages />

                <div className="container pt-0 mt-0">
                    <Forms irHaSolicitud={this.irHaSolicitud} />

                    <GoogleReCaptchaProvider reCaptchaKey="6LdOXxApAAAAACZpjBgggvzLYoYJzj4_a4AXI9Lb">
                        <GoogleReCaptcha />
                    </GoogleReCaptchaProvider>
                </div>

                <br /> <br /> <br /> <br /> <br />

            </React.Fragment>
        )
    }
}

export default withRouter(LayoutAgregarSolicitud);