import React from "react";
import Navbarpages from "../navbar-pages";
import { Link } from "react-router-dom";
import { withRouter } from "../../withRouter";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Persona from "../../models/Persona";
import { isMobile } from 'react-device-detect';
import apiCreditoOnline from "../../services/creditoonlineApi";
import apiFuturoLamanense from "../../services/futurolamanenseApi";
import CurrencyFormat from 'react-currency-format';
import SweetAlert2 from "react-sweetalert2";

class LayoutMisDatos extends React.Component {

    state = {
        provincias: [],
        cantones: [],
        parroquias: [],
        recintossector:[],
        estadocivil: [],
        tipovivienda: [],
        tipoeducacion: [],
        etnias: [],
        profesiones: [],
        actividadeseconomicas: [],
        formpersona: {
            'idpersona': 0,
            'tipoidentificacion': '',
            'identificacion': '',
            'codigodactilar': '',
            'nombres': '',
            'apellidos': '',
            'fechanacimiento': '',
            'sexo': '',
            'estadocivil': '',
            'paisnacimiento': '',
            'email': '',
            'cargasfamiliares': 0,
            'tipoeducacion': '',
            'profesion': '',
            'actividadeconomica': '',
            'etnia': '',
            'idprovincia': '',
            'idcanton': '',
            'idsector': '',
            'codigorecinto': '',
            'tipovivienda': '',
            'celular': '',
            'direcciondomicilio': '',
            'referenciadomicilio': '',
            'essocio': 0,
            'fechacreacion': '',
            'ingresosueldo': '',
            'ingresonegocio': '',
            'ingresopensiones': '',
            'ingresootros': '',
            'egresoalimentacion': '',
            'egresoarriendos': '',
            'egresoeducacion': '',
            'egresosalud': '',
            'egresoserviciosbasicos': '',
            'egresotransporte': ''
        },
        paises: [],
        swal: {},
    }

    componentDidMount() {
        if (sessionStorage.getItem('identificacion') == '' || sessionStorage.getItem('identificacion') == null) {
            this.props.navigate('/')
        } else {
            this.getDataPersona();
            this.getPaises();
            this.getProvincias();
            this.getEstadoCivil();
            this.getTipoVivienda();
            this.getTipoEducacion();
            this.getProfesiones();
            this.getActividadesEconomicas();
            this.getEtnias();
        }
    }

    getEstadoCivil = async () => {
        try {
            const data = await apiFuturoLamanense.persona.getestadocivil();
            this.setState({ estadocivil: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getTipoEducacion = async () => {
        try {
            const data = await apiFuturoLamanense.persona.gettipoeducacion();
            this.setState({ tipoeducacion: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getProfesiones = async () => {
        try {
            const data = await apiFuturoLamanense.persona.getprofesiones();
            this.setState({ profesiones: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getActividadesEconomicas = async () => {
        try {
            const data = await apiFuturoLamanense.generales.getactividadeseconomicas();
            this.setState({ actividadeseconomicas: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getEtnias = async () => {
        try {
            const data = await apiFuturoLamanense.persona.getetnias();
            this.setState({ etnias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getTipoVivienda = async () => {
        try {
            const data = await apiFuturoLamanense.persona.gettipovivienda();
            this.setState({ tipovivienda: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getDataPersona = async () => {
        try {
            const data = await apiCreditoOnline.personas.getpersonaidentificacion(sessionStorage.getItem('identificacion'));
            this.setState({ formpersona: data.response });
            this.getCantones(data.response.idprovincia);
            this.getParroquias(data.response.idcanton);
            this.getRecintoSector(data.response.idsector);
        } catch (error) {
            console.log(error);
        }
    }

    getProvincias = async () => {
        try {
            const data = await apiFuturoLamanense.generales.getprovincias();
            this.setState({ provincias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getCantones = async (secuencialDivisionPadre) => {
        try {
            const data = await apiFuturoLamanense.generales.getcantones(secuencialDivisionPadre);
            this.setState({ cantones: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getParroquias = async (secuencialDivisionPadre) => {
        try {
            const data = await apiFuturoLamanense.generales.getParroquias(secuencialDivisionPadre);
            this.setState({ parroquias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getRecintoSector = async (secuencialDivisionPolitica) => {
        try {
            const data = await apiFuturoLamanense.generales.getRecintosSector(secuencialDivisionPolitica);
            this.setState({ recintossector: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    handleChangePersona = (e) => {
        var valor = null;
        valor = e.target.value
        this.setState({
            formpersona: {
                ... this.state.formpersona,
                [e.target.name]: valor,
            }
        })
    }
    
    cerrarSwal = () => {
        setTimeout(() => {
            this.setState({
                swal: {
                    show: false,
                },
            });
        }, 3000)
    }

    actualizarDatosPersonas = async (e) => {
        e.preventDefault();
        try {
            let persona = new Persona(
                this.state.formpersona.idpersona,
                this.state.formpersona.tipoidentificacion,
                this.state.formpersona.identificacion,
                this.state.formpersona.codigodactilar,
                this.state.formpersona.nombres,
                this.state.formpersona.apellidos,
                this.state.formpersona.fechanacimiento,
                +this.state.formpersona.sexo,
                this.state.formpersona.estadocivil,
                this.state.formpersona.paisnacimiento,
                this.state.formpersona.email,
                +this.state.formpersona.cargasfamiliares,
                this.state.formpersona.tipoeducacion,
                this.state.formpersona.profesion,
                this.state.formpersona.actividadeconomica,
                this.state.formpersona.etnia,
                +this.state.formpersona.idprovincia,
                +this.state.formpersona.idcanton,
                +this.state.formpersona.idsector,
                this.state.formpersona.codigorecinto,
                this.state.formpersona.tipovivienda,
                this.state.formpersona.celular,
                this.state.formpersona.direcciondomicilio,
                this.state.formpersona.referenciadomicilio,
                this.state.formpersona.essocio,
                this.state.formpersona.fechacreacion,
                +this.state.formpersona.ingresosueldo,
                +this.state.formpersona.ingresonegocio,
                +this.state.formpersona.ingresopensiones,
                +this.state.formpersona.ingresootros,
                +this.state.formpersona.egresoalimentacion,
                +this.state.formpersona.egresoarriendos,
                +this.state.formpersona.egresoeducacion,
                +this.state.formpersona.egresosalud,
                +this.state.formpersona.egresoserviciosbasicos,
                +this.state.formpersona.egresotransporte
            );
            await apiCreditoOnline.personas.update(persona);
            this.setState({
                swal: {
                    show: true,
                    title: 'Hecho',
                    text: 'Datos actualizados correctamente!!',
                    icon: 'success',
                },
            });
            this.cerrarSwal();
        } catch (error) {
            console.log(error);
        }
    }

    headIsMobile = () => {
        if (!isMobile) {
            return (
                <React.Fragment>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/'}>Inicio</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={'/consultar-solicitud'}>Consulta de solicitudes de crédito</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Mis Datos</li>
                        </ol>
                    </nav>
                    <br />
                    <h5 className="pb-3 border-bottom border-primary text-primary mb-3">
                        <b><i className="fas fa-id-card"></i> MIS DATOS</b>
                    </h5>
                    <br />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'}>
                                    <small>Inicio</small>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <Link to={'/consultar-solicitud'}>
                                    <small>Consulta de solicitudes de crédito</small>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <small>Mis Datos</small>
                            </li>
                        </ol>
                    </nav>
                    <p className="pb-2 border-bottom border-primary text-primary mb-3">
                        <b><i className="fas fa-id-card"></i> MIS DATOS</b>
                    </p>
                </React.Fragment>
            )
        }
    }

    getPaises = async () => {
        try {
            const data = await apiFuturoLamanense.generales.getpaises();
            this.setState({ paises: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <Navbarpages />
                <SweetAlert2 {...this.state.swal} />

                <form onSubmit={this.actualizarDatosPersonas}>
                    <div className="container">

                        {this.headIsMobile()}

                        <div className="row mt-0">
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="tipoidentificacion" value={this.state.formpersona.tipoidentificacion} required disabled="disabled">
                                        <option value="C">Cédula</option>
                                        <option value="R">RUC</option>
                                    </select>
                                    <label >Tipo de indentificación</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="text" className="form-control" onChange={this.handleChangePersona} name="identificacion" value={this.state.formpersona.identificacion} required disabled="disabled" />
                                    <label htmlFor="floatingInput">Identificación</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="text" className="form-control" onChange={this.handleChangePersona} name="codigodactilar" value={this.state.formpersona.codigodactilar} required />
                                    <label htmlFor="floatingInput">Código Dactilar</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="text" className="form-control" onChange={this.handleChangePersona} name="nombres" value={this.state.formpersona.nombres} required />
                                    <label htmlFor="floatingInput">Nombres</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="text" className="form-control" onChange={this.handleChangePersona} name="apellidos" value={this.state.formpersona.apellidos} required />
                                    <label htmlFor="floatingInput">Apellidos</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="sexo" value={this.state.formpersona.sexo} required >
                                        <option value="">Seleccione el sexo...</option>
                                        <option value="1">Masculino</option>
                                        <option value="0">Femenino</option>
                                    </select>
                                    <label >Sexo</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <select className="form-select" onChange={this.handleChangePersona} name="estadocivil" value={this.state.formpersona.estadocivil} required>
                                        <option value="" className="seleccion-inicio">Seleccione el estado civil...</option>
                                        {
                                            this.state.estadocivil.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="floatingSelect">Estado civil</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <select className="form-select" onChange={this.handleChangePersona} name="paisnacimiento" value={this.state.formpersona.paisnacimiento} required>
                                        <option value="" className="seleccion-inicio">Seleccione el país de nacimiento...</option>
                                        {
                                            this.state.paises.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="floatingSelect">País de nacimeinto</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="date" className="form-control" onChange={this.handleChangePersona} name="fechanacimiento" value={this.state.formpersona.fechanacimiento} required />
                                    <label htmlFor="floatingInput">Fecha de nacimiento</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="email" className="form-control" onChange={this.handleChangePersona} name="email" value={this.state.formpersona.email} required />
                                    <label htmlFor="floatingInput">Correo electrónico</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="number" className="form-control" onChange={this.handleChangePersona} name="cargasfamiliares" value={this.state.formpersona.cargasfamiliares} />
                                    <label htmlFor="floatingInput">Cargas familiares</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="tipoeducacion" value={this.state.formpersona.tipoeducacion} required >
                                        <option value="">Seleccione el tipo educación...</option>
                                        {
                                            this.state.tipoeducacion.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Tipo Educación</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="profesion" value={this.state.formpersona.profesion} required >
                                        <option value="">Seleccione la profesión...</option>
                                        {
                                            this.state.profesiones.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Profesión</label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="actividadeconomica" value={this.state.formpersona.actividadeconomica} required >
                                        <option value="">Seleccione la actividad económica...</option>
                                        {
                                            this.state.actividadeseconomicas.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Actividad económica</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="etnia" value={this.state.formpersona.etnia} required >
                                        <option value="">Seleccione el tipo de etnia...</option>
                                        {
                                            this.state.etnias.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Tipo Etnia</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating ">
                                    <input type="text" className="form-control" onChange={this.handleChangePersona} name="celular" value={this.state.formpersona.celular} required />
                                    <label htmlFor="floatingInput">Celular</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className=" nav-ss p-2 mb-0">
                            <b >Información de domicilio</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="idprovincia" value={this.state.formpersona.idprovincia} required >
                                        <option value="">Seleccione la provincia...</option>
                                        {
                                            this.state.provincias.map((data, index) => {
                                                return (
                                                    <option value={data.secuencial} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Provincia de domicilio</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="idcanton" value={this.state.formpersona.idcanton} required >
                                        <option value="">Seleccione el cantón...</option>
                                        {
                                            this.state.cantones.map((data, index) => {
                                                return (
                                                    <option value={data.secuencial} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Cantón de domicilio</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="idsector" value={this.state.formpersona.idsector} required >
                                        <option value="">Seleccione el parroquia...</option>
                                        {
                                            this.state.parroquias.map((data, index) => {
                                                return (
                                                    <option value={data.secuencial} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Parroquia</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChangePersona} name="codigorecinto" value={this.state.formpersona.codigorecinto} required >
                                        <option value="">Seleccione el recinto / sector...</option>
                                        {
                                            this.state.recintossector.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label >Parroquia</label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <select className="form-select" onChange={this.handleChangePersona} name="tipovivienda" value={this.state.formpersona.tipovivienda} required>
                                        <option value="" className="seleccion-inicio">Seleccione tipo vivienda...</option>
                                        {
                                            this.state.tipovivienda.map((data, index) => {
                                                return (
                                                    <option value={data.codigo} key={index + 1} >
                                                        {data.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor="floatingSelect">Tipo de vivienda</label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="form-floating">
                                    <textarea className="form-control" id="floatingTextarea" onChange={this.handleChangePersona} name="direcciondomicilio" value={this.state.formpersona.direcciondomicilio} required></textarea>
                                    <label htmlFor="floatingTextarea">Dirección domicilio</label>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="form-floating">
                                    <textarea className="form-control" id="floatingTextarea" onChange={this.handleChangePersona} name="referenciadomicilio" value={this.state.formpersona.referenciadomicilio}></textarea>
                                    <label htmlFor="floatingTextarea">Referencia domicilio</label>
                                </div>
                            </div>


                        </div>
                        <br />
                        <div className=" nav-ss p-2 mb-2">
                            <b >Ingresos</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="ingresosueldo" value={this.state.formpersona.ingresosueldo} />
                                    <label htmlFor="floatingInput">Tiene Ingresos de Sueldo? </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="ingresonegocio" value={this.state.formpersona.ingresonegocio} />
                                    <label htmlFor="floatingInput">Tiene Ingresos de Negocio? </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="ingresopensiones" value={this.state.formpersona.ingresopensiones} />
                                    <label htmlFor="floatingInput">Tiene Ingresos de Pensiones? </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="ingresootros" value={this.state.formpersona.ingresootros} />
                                    <label htmlFor="floatingInput">Tiene Otros Ingresos? </label>
                                </div>
                            </div>

                        </div>
                        <div className=" nav-ss p-2 mb-2">
                            <b >Egresos</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="egresoalimentacion" value={this.state.formpersona.egresoalimentacion} required />
                                    <label htmlFor="floatingInput">Gastos de alimentación </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="egresoarriendos" value={this.state.formpersona.egresoarriendos} />
                                    <label htmlFor="floatingInput">Gastos en arriendos </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="egresoeducacion" value={this.state.formpersona.egresoeducacion} />
                                    <label htmlFor="floatingInput">Gastos en educación </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="egresosalud" value={this.state.formpersona.egresosalud} />
                                    <label htmlFor="floatingInput">Gastos en salud </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="egresoserviciosbasicos" value={this.state.formpersona.egresoserviciosbasicos} />
                                    <label htmlFor="floatingInput">Gastos en servicios básicos </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="form-floating mb-3">
                                    <CurrencyFormat className="form-control" onChange={this.handleChangePersona} name="egresotransporte" value={this.state.formpersona.egresotransporte} />
                                    <label htmlFor="floatingInput">Gastos en transporte </label>
                                </div>
                            </div>
                        </div>
                        <div className="text-end border-top pt-3">
                            <Link to={'/consultar-solicitud'} className="btn btn-outline-secondary me-3" type="button" >
                                <i className="fas fa-window-close"></i> Cancelar
                            </Link>
                            <button className="btn btn-primary" type="submit">
                                <i className="fas fa-edit"></i> Actualizar
                            </button>
                        </div>
                    </div>
                </form>

                <br /> <br />

            </React.Fragment>
        )
    }
}

export default withRouter(LayoutMisDatos);