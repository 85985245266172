import Global from "./urlGlobal";
const BASEE = Global.UrlGlobal.urlApi;

async function callApi(endpoint, options) {
    const url = BASEE + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiCreditoOnline = {
    solicitudes: {
        create(data) {
            return callApi(`/solicitudes/solicitud`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
        },
        verificarSiTieneUltimaSolicitudDentroDelRangoFecha(idpersona) {
            return callApi(`/solicitudes/verificar-ultima-solicitud/` + idpersona);
        },
        solicitudesporpersona(idpersona) {
            return callApi(`/solicitudes/solicitudes-persona/` + idpersona);
        },
        agenciaone(idagencia) {
            return callApi(`/solicitudes/agencia-one/` + idagencia);
        },
        cancelarsolicitud(idsolicitud) {
            return callApi(`/solicitudes/cancelar-solicitud/` + idsolicitud);
        },
    },
    personas: {
        getpersonaidentificacion(identificacion) {
            return callApi(`/persona/persona-identificacion/` + identificacion);
        },
        getdataExistente(identificacion) {
            return callApi(`/persona/datapersona/` + identificacion);
        },
        update(data) {
            return callApi(`/persona/persona`, {
                method: 'PUT',
                body: JSON.stringify(data),
            });
        },
        getVerificarSiEsSocio(identificacion) {
            return callApi(`/persona/verificar-sies-socio/` + identificacion);
        },
    },
    motivocancelacion: {
        getmotivocancelacion(idsolicitud) {
            return callApi(`/motivocancelacion/motivo-cancelacion/` + idsolicitud);
        },
    }
}

export default apiCreditoOnline;