import Global from "./urlGlobal";
const BASEE = Global.UrlGlobal.urlApi;

async function callApi(endpoint, options) {
    const url = BASEE + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiJustificacion = {
    justificacion: {

        create(data) {
            return callApi(`/justificacionlaboral/justificacion-laboral-imagen`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
        },

        getJustificaciones(idsolicitud) {
            return callApi(`/justificacionlaboral/justificacion-laboral-imagen/` + idsolicitud);
        },

    }
};

export default apiJustificacion;