import React from "react";

class Beneficios extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid beneficios pt-5 pb-5">
                    <div className="text-center">
                        <h3>Beneficios</h3>
                        <p>Solicitudes de crédito sin necesidad que acercarte a nuestras oficinas</p>
                    </div>
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card text-center border-white">
                                    <div className="card-body">
                                        <br />
                                        <i className="fas fa-tachometer-alt icons-beneficios"></i>
                                        <br /> <br />
                                        <h4 className="titulo-beneficios">AGILIDAD</h4>
                                        <p>Tu solicitud sera verificada en un plazo máximo de 24 a 48 horas</p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="card text-center border-white">
                                    <div className="card-body">
                                        <br />
                                        <i className="fas fa-vote-yea icons-beneficios"></i>
                                        <br /> <br />
                                        <h4 className="titulo-beneficios">FLEXIBILIDAD</h4>
                                        <p>Si cancelas el 50% de tu microcrédito, accede a una novación de acuerdo a tu capacidad de pago.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="card text-center border-white">
                                    <div className="card-body">
                                        <br />
                                        <i className="fas fa-user-plus icons-beneficios"></i>
                                        <br /> <br />
                                        <h4 className="titulo-beneficios">BENEFICIOS</h4>
                                        <p>Seguro médico, seguro de gravamen, servicios de odontología, peluquería y un fondo mortuorio hasta $5.000</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="card text-center border-white">
                                    <div className="card-body">
                                        <br />
                                        <i className="fas fa-user-friends icons-beneficios"></i>
                                        <br /> <br />
                                        <h4 className="titulo-beneficios">FACILIDAD</h4>
                                        <p>Las cuotas las puedes cancelar desde cualquiera de nuestras Agencias o por transferencias electrónicas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Beneficios;