import React from "react";
import LandingImg from "./../../imagenes/onlinecredito.png";
import Beneficios from "./beneficios";
import Coberturadigital from "./cobertura-digital";
import "./inicio.css";
import Requisitos from "./requisitos";
import Logo from "./../../imagenes/logohorizont.svg";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class Inicio extends React.Component {

    componentDidMount() {
        sessionStorage.removeItem('identificacion');
        sessionStorage.removeItem('tipoidentificacion');
    }

    botonesHead = () => {
        if (!isMobile) {
            return (
                <React.Fragment>
                    <div className='row'>
                        <div className='col-lg-4 '>
                            <img src={Logo} className="logo p-0 m-0" />
                        </div>
                        <div className='col-lg-8 text-end pt-4 pl-0'>
                            <Link to={'/simulador-credito'} className='btn btn-simulador rounded-pill ps-4 pe-4 me-3'>
                                Simulador de Crédito
                            </Link>
                            <a href="https://bdfl.futurolamanense.fin.ec/login" target="_blank" className='btn btn-billetera rounded-pill ps-4 pe-4'>
                                Billetera Digital
                            </a>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid  m-0">
                    {this.botonesHead()}
                </div>
                <div className="contenedor-imagen">
                    <img src={LandingImg} className="w-100" />
                </div>

                <br /> <br />

                <Coberturadigital />

                <br /> <br />

                <Beneficios />

                <br />  <br />

                <Requisitos />

                <br />  <br />

            </React.Fragment>
        )
    }
}

export default Inicio;