import React from "react";
import CoberturaDigitalPng from "./../../imagenes/requ.png";
import { withRouter } from "../../withRouter";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';
import apiCreditoOnline from "../../services/creditoonlineApi";

class Coberturadigital extends React.Component {

    constructor() {
        super()
    }

    state = {
        form: {
            'tipoidentificacion': 'C',
            'identificacion': ''
        },
        erroridentificacion: ''
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    verificarDataPersona = async (e) => {
        e.preventDefault();
        sessionStorage.setItem('identificacion', this.state.form.identificacion);
        sessionStorage.setItem('tipoidentificacion', this.state.form.tipoidentificacion);
        if (this.state.form.tipoidentificacion == "C") {
            if (this.isValidCedula(this.state.form.identificacion)) {
                this.obtenerEnlaces();
            }
        }
        /* else if (this.state.form.tipoidentificacion == "R") {
            if (this.state.form.identificacion.toString().length != 13) {
                this.setState({ erroridentificacion: "El RUC debe ser de: 13 d\u00EDgitos." })
            } else {
                this.obtenerEnlaces();
            }
        }*/

    }

    obtenerEnlaces = async () => {
        try {
            const data = await apiCreditoOnline.personas.getpersonaidentificacion(this.state.form.identificacion);
            if (data.response) {
                sessionStorage.setItem('isregisterok', "true");
                this.props.navigate('/consultar-solicitud');
            } else {
                sessionStorage.setItem('isregisterok', "false");
                this.props.navigate('/agregar-solicitud')
            }
        } catch (error) {
            console.log(error)
        }
    }

    isValidCedula(ci) {
        var isNumeric = true;
        var total = 0,
            individual;

        for (var position = 0; position < 10; position++) {
            // Obtiene cada posición del número de cédula
            // Se convierte a string en caso de que 'ci' sea un valor numérico
            individual = ci.toString().substring(position, position + 1)

            if (isNaN(individual)) {
                isNumeric = false;
                break;
            } else {
                // Si la posición es menor a 9
                if (position < 9) {
                    // Si la posición es par, osea 0, 2, 4, 6, 8.
                    if (position % 2 == 0) {
                        // Si el número individual de la cédula es mayor a 5
                        if (parseInt(individual) * 2 > 9) {
                            // Se duplica el valor, se obtiene la parte decimal y se aumenta uno 
                            // y se lo suma al total
                            total += 1 + ((parseInt(individual) * 2) % 10);
                        } else {
                            // Si el número individual de la cédula es menor que 5 solo se lo duplica
                            // y se lo suma al total
                            total += parseInt(individual) * 2;
                        }
                        // Si la posición es impar (1, 3, 5, 7)
                    } else {
                        // Se suma el número individual de la cédula al total
                        total += parseInt(individual);
                    }
                }
            }
        }

        if ((total % 10) != 0) {
            total = (total - (total % 10) + 10) - total;
        } else {
            total = 0;
        }


        if (isNumeric) {
            // La cédula debe contener al menos 10 dígitos
            if (ci.toString().length != 10) {
                this.setState({ erroridentificacion: "La c\u00E9dula debe ser de: 10 d\u00EDgitos." })
                return false;
            }

            // El número de cédula no debe ser cero
            if (parseInt(ci, 10) == 0) {
                this.setState({ erroridentificacion: "La c\u00E9dula ingresada no puede ser cero." })
                return false;
            }

            // El total debe ser igual al último número de la cédula
            if (total != parseInt(individual)) {
                this.setState({ erroridentificacion: "La c\u00E9dula ingresada no es v\u00E1lida." })
                return false;
            }
            return true;
        }

        // Si no es un número  
        this.setState({ erroridentificacion: "El dato solo puede contener numeros." })
        return false;
    }

    msgError = () => {
        if (this.state.erroridentificacion != '') {
            return (
                <React.Fragment>
                    <div className="alert alert-danger" role="alert">
                        {this.state.erroridentificacion}
                    </div>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="text-center">
                        <h3>Cobertura digital <span className="text-primary">eficiente</span> y <span className="text-success">segura</span></h3>
                        <p>La forma fácil y rápida de solicitar un crédito</p>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-6 text-center">
                            <img src={CoberturaDigitalPng} className="w-75" />
                        </div>
                        <div className="col-lg-4">
                            <form onSubmit={this.verificarDataPersona}>
                                <h5 className="mt-3">Ingresa la siguente información para acceder al crédito en línea</h5>
                                <br />
                                <div className="form-floating">
                                    <select className="form-select" id="floatingSelect" onChange={this.handleChange} name="tipoidentificacion" value={this.state.form.tipoidentificacion} required>
                                        <option value="C">Cédula</option>
                                        {/* <option value="R">RUC</option> */}
                                    </select>
                                    <label >Tipo de indentificación</label>
                                </div>
                                <br />
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="floatingInput" onChange={this.handleChange} name="identificacion" value={this.state.form.identificacion} required
                                    onKeyDown={(e) => {
                                        if(e.key === "Enter") {
                                            this.verificarDataPersona(e)
                                        }
                                    }} />
                                    <label htmlFor="floatingInput">Identificación</label>
                                </div>

                                {this.msgError()}

                                <GoogleReCaptchaProvider reCaptchaKey="6LdOXxApAAAAACZpjBgggvzLYoYJzj4_a4AXI9Lb">
                                    <GoogleReCaptcha  />
                                </GoogleReCaptchaProvider>

                                <div className="text-end">
                                    <button type="submit" className="btn btn-siguiente btn-lg ps-4 pe-4">Siguiente</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Coberturadigital);