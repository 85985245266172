import React, { Component } from "react";
import SweetAlert2 from 'react-sweetalert2';

class JustificacionesForm extends Component {

    state = {
        swal: {},
        tablapresuntiva: [],
        tablapresuntivashow: 'false'
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    uploadImagenesJustificacion = () => {
        if (this.props.formdatoslaboral.tipodependencia == 'Dependiente' || this.props.formdatoslaboral.tipodependencia == 'Independiente') {
            return (
                <div className="">
                    {this.tituloJustificacion()}
                    {this.errorMsjExtension()}
                    <div className="row mt-2">
                        <div className="col-lg-3">
                            <div className="card p-2 bg-light">
                                <div className="text-danger text-center textwn" >
                                    Subir documentos claros y legibles
                                </div>
                                <div className="img-subir w-100 mt-3 mb-2" >
                                    <label htmlFor="nuestroinput">
                                        <i className="fas fa-file-upload"></i> Subir archivo
                                    </label>
                                    <input id="nuestroinput" name="inputFile" value={this.props.formFile.inputFile} className="upload ml-5 " type="file"
                                        onChange={(e) => this.subirArchivo(e.target.files[0], e)} />

                                    <div className="text-center text-success mt-2">
                                        {this.props.formjustificacion.imagen}
                                    </div>
                                </div>
                                <small className="mb-0">Descripción del documento</small>
                                <input className="form-control mt-0" id="descripcionjustificacion" onChange={this.props.handleChangeJustificacion} name="descripcion" value={this.props.formjustificacion.descripcion} required />
                                <div className="text-end pb-2">
                                    <button type="submit" className="btn btnagregarjustificacion btn-sm mt-2" onClick={() => this.props.agregarJustificacion()}>
                                        <i className="fas fa-plus"></i> Agregar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="table-responsive">
                                <table className="table table-sm mt-3">
                                    <thead className="table-success">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Documentos de justificación</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.archivosJustificacion.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.descripcion}</td>
                                                        <td>{data.imagen}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => this.props.eliminarFilaJustificacion(index)}>
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    eliminarFilaJustificacion = (pocision) => {
        this.props.archivosJustificacion.splice(pocision, 1);
        this.setState({ state: this.state });
    }

    errorMsjExtension = () => {
        if (this.props.archivoExtension == false) {
            return (
                <div className="alert alert-danger pt-1 pb-1" role="alert">
                    Error, solo se admiten archivos con las extenciones: pdf, docx, jpg, jpeg, png, gif, webp, svg.
                </div>
            )
        }
    }

    tituloJustificacion = () => {
        if (this.props.formdatoslaboral.tipodependencia == 'Dependiente') {
            return (
                <p className="text-dark mt-1 fondo-titulo ps-2 pe-2 pt-1 pb-1 mb-0">Subir los 3 ultimos roles de pagos o facturas de prestación de servicios de los ultimos meses</p>
            )
        } else if (this.props.formdatoslaboral.tipodependencia == 'Independiente') {
            return (
                <p className="text-secondary mt-1 fondo-titulo ps-2 pe-2 pt-1 pb-1 mb-0">Subir las declaraciones del impuesto a la renta del ultimo año o certificados comerciales de compra de productos</p>
            )
        }
    }

    subirArchivo = (file, e) => {
        if (file) {
            var extension = file.name.split('.').pop();
            extension = extension.toLowerCase();
            if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif' || extension === 'webp' || extension === 'svg' || extension === 'pdf' || extension === 'docx') {
                this.setState({ archivoExtension: true });
                this.props.formjustificacion.imagen = this.uuidv4() + '.' + extension;
                if (file) {
                    var reader = new FileReader();
                    reader.onload = this._handleReaderLoaded1.bind(this);
                    reader.readAsBinaryString(file);
                }
            } else {
                this.setState({ archivoExtension: false })
            }
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    _handleReaderLoaded1(readerEvent) {
        var binaryString = readerEvent.target.result;
        this.props.formjustificacion.file = btoa(binaryString)
        //this.setState({ formjustificacion: { binarioArchivo: btoa(binaryString) } })
    }

    errorDescripcion = () => {
        const { handleChangeCrediticios, formcrediticios } = this.props;
        if (this.props.campovacio1 == 'true') {
            return (
                <React.Fragment>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control is-invalid" onChange={handleChangeCrediticios} name="destino" value={formcrediticios.destino} required />
                        <label htmlFor="floatingInput" className="color-require">¿Para que necesita el crédito? <span className="color-require">*</span></label>
                    </div>

                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" onChange={handleChangeCrediticios} name="destino" value={formcrediticios.destino} required />
                        <label htmlFor="floatingInput">¿Para que necesita el crédito? <span className="color-require">*</span></label>
                    </div>
                </React.Fragment>
            )
        }
    }

    mascarafecha = (fecha) => {
        var event = new Date(fecha);
        let date = JSON.stringify(event)
        date = date.slice(1, 11)
        return date;
    }  

    render() {
        return (
            <div>
                <SweetAlert2 {...this.state.swal} />
                <div className=" nav-ss p-2 mb-2">
                    <b >Justificaciones</b>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {this.errorDescripcion()}
                    </div>
                </div>

                {this.uploadImagenesJustificacion()}

                <hr />

                <div className="text-end">
                    <button className='buttons__button buttons__button--back' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ver Tabla Presuntiva</button>
                    <button className='buttons__button buttons__button--back' onClick={this.back}>Atrás</button>
                    <button className="btnsiguiente" onClick={() => this.props.procesarFiltro2()}>
                        Siguiente
                    </button>
                </div>
                <br />

                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">TABLA PRESUNTIVA DE PAGOS</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="container">
                        <div className="row font-letter">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Tipo de prestamo:</b> </div>
                                    <div className="col-lg-6"> {this.props.datasimulador.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.DescripcionTipoPrestamo} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>No. cuotas:</b> </div>
                                    <div className="col-lg-6"> {this.props.formcrediticios.plazomeses} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Frecuencia de pago:</b> </div>
                                    <div className="col-lg-6"> 30 </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Monto solicitado:</b> </div>
                                    <div className="col-lg-6"> ${this.props.formcrediticios.monto} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Valor Tasa:</b> </div>
                                    <div className="col-lg-6"> {this.props.datasimulador.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ValorTasa} </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Fecha de adjudicación:</b> </div>
                                    <div className="col-lg-6"> {this.mascarafecha(this.props.datasimulador.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.FechaAdjudicacion)} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Fecha de vencimiento:</b> </div>
                                    <div className="col-lg-6"> {this.mascarafecha(this.props.datasimulador.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.FechaVencimiento)} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Deuda inicial:</b> </div>
                                    <div className="col-lg-6"> ${this.props.formcrediticios.monto} </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 text-end"> <b>Valor a entregar:</b> </div>
                                    <div className="col-lg-6"> ${this.props.formcrediticios.monto}</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Cuota</th>
                                        <th scope="col">Fecha de pago</th>
                                        <th scope="col">Capital</th>
                                        <th scope="col">Interés</th>
                                        <th scope="col">Otros</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Saldo capital</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.datasimulador.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla.TablaPagos_PorCuotas.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.NumeroCuota}</td>
                                                    <td>{this.mascarafecha(data.FechaPago)}</td>
                                                    <td>${data.ValorCapital}</td>
                                                    <td>${data.ValorInteres}</td>
                                                    <td>${data.ValorOtros}</td>
                                                    <td>${data.ValorTotal}</td>
                                                    <td>${data.SaldoCapital}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default JustificacionesForm;