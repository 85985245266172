import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Inicio from './pages/inicio/inicio';
import LayoutAgregarSolicitud from './pages/agregar-solicitud/layout-agregar-solicitud';
import NoMatch from './pages/nomatch';
import React from 'react';
import LayoutConsultaSolicitud from './pages/consulta-solicitud/layout-consulta-solicitud';
import LayoutSimuladorCredito from './pages/simulador-credito/layout-simulador-credito';
import LayoutMisDatos from './pages/mis-datos/layout-mis-datos';

const router = createBrowserRouter([
  {
    path: "/",
    Component: Inicio
  },
  {
    path: "/agregar-solicitud",
    Component: LayoutAgregarSolicitud
  },
  {
    path: "/consultar-solicitud",
    Component: LayoutConsultaSolicitud
  },
  {
    path: "/simulador-credito",
    Component: LayoutSimuladorCredito
  },
  {
    path: "/mis-datos",
    Component: LayoutMisDatos
  },
  {
    path: "*",
    element: <NoMatch />,
  },
]);

function App() {
  return (
    <React.Fragment>

      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />

      <br /> <br /> <br />
      <footer>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6 text-center'>
              © Copyright <b>Futuro Lamanense</b>. All Rights Reserved
            </div>
            <div className='col-lg-6 text-center'>
              Desarrollado por Departamento de TI
            </div>
          </div>
        </div>
      </footer>

    </React.Fragment>
  );
}

export default App;
