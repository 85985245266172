import React from "react";
import RequisitoCreditoImg from "./../../imagenes/cbd.png";

class Requisitos extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center">
                            <img src={RequisitoCreditoImg} className="w-75" />
                        </div>
                        <div className="col-lg-5">
                            <br />
                            <h3 className="titulo-requisitos">Requisitos</h3>
                            <br />
                            <ul>
                                <li><span>Cédula de identidad y papeleta de votación.</span></li>
                                <li><span>Mayor de 18 años.</span></li>
                                <li><span>Justificación de ingresos (Roles de pago, RUC o RIMPE, declaraciones o facturas, certificado comercial).</span></li>
                                <li><span>Planilla de servicio básico.</span></li>
                                <li><span>Original de impuesto predial (en caso de tenerlo).</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Requisitos;