import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApiGolangSimulador;
const BASE_URL_SIMULADOR = Global.UrlGlobal.urlApiSimulador;

async function callApi(endpoint, options) {
    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

async function callApiSimulador(endpoint, options = {}) {
    const url = BASE_URL_SIMULADOR + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiSimulador = {
    simulador: {

        inversion(data) {
            return callApi(`/calcularinversiones`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
        },

        segmentointerno(data) {
            return callApiSimulador(`/simulator/segmentointerno`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        },

        subcalificacion(cod) {
            return callApiSimulador(`/simulator/subcalificacion`, {
                method: 'POST',
                body: JSON.stringify({ secuencial: cod }),
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        },

        consultarCredito(tipoPrestamo, tipoTabla, segmentoInterno, subcalificacion, fecha, monto, diafijo, numCuotas, frecuenciaPago) {
            return callApiSimulador(`/simulator/prestamo`, {
                method: 'POST',
                body: JSON.stringify({ tipoPrestamo: tipoPrestamo, tipoTabla: tipoTabla, segmentoInterno: segmentoInterno, subcalificacion: subcalificacion, fecha: fecha, monto: monto, diaFijo: diafijo, numCuotas: numCuotas, frecuenciaPago: frecuenciaPago }),
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        }

    }
};

export default apiSimulador;