import React from "react";
import CurrencyFormat from 'react-currency-format';

class IngresosyEgresos extends React.Component {

    esSalarioNegocios = () => {
        const { formingresos, handleChangeIngresos } = this.props;
        if (this.props.formdatoslaboral.tipodependencia == 'Dependiente') {
            return (
                <React.Fragment>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat  className="form-control" onChange={handleChangeIngresos} name="ingresonegocio" value={formingresos.ingresonegocio} />
                            <label htmlFor="floatingInput">Tiene Ingresos de Negocio? </label>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    render() {
        const { formingresos, handleChangeIngresos, formegresos, handleChangeEngresos } = this.props;
        return (
            <React.Fragment>
                <br />
                <div className=" nav-ss p-2 mb-2">
                    <b >Ingresos</b>
                </div>
                <div className="row">
                    {this.esSalarioNegocios()}
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeIngresos} name="pensiones" value={formingresos.pensiones} />
                            <label htmlFor="floatingInput">Tiene Ingresos de Pensiones? </label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat  type="text" className="form-control" onChange={handleChangeIngresos} name="otros" value={formingresos.otros} />
                            <label htmlFor="floatingInput">Tiene Otros Ingresos? </label>
                        </div>
                    </div>
                </div>

                <br />
                <div className=" nav-ss p-2 mb-2">
                    <b >Egresos</b>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeEngresos} name="alimentacion" value={formegresos.alimentacion} required />
                            <label htmlFor="floatingInput">Gastos en Alimentación <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeEngresos} name="arriendos" value={formegresos.arriendos} />
                            <label htmlFor="floatingInput">Gastos en Arriendos </label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeEngresos} name="educacion" value={formegresos.educacion} required />
                            <label htmlFor="floatingInput">Gastos en Educación <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeEngresos} name="salud" value={formegresos.salud} required />
                            <label htmlFor="floatingInput">Gastos en Salud <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeEngresos} name="serviciosbasicos" value={formegresos.serviciosbasicos} required />
                            <label htmlFor="floatingInput">Gastos en Servicios Básicos <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat   type="text" className="form-control" onChange={handleChangeEngresos} name="transporte" value={formegresos.transporte} required />
                            <label htmlFor="floatingInput">Gastos en Transporte <span className="color-require">*</span></label>
                        </div>
                    </div>
                </div>

                <div className="text-end">
                    <button className="btnsiguiente" type="submit">
                        Siguiente
                    </button>
                </div>


            </React.Fragment>
        )
    }
}

export default IngresosyEgresos