import React from "react";
import '../../App.css';
import apiFuturoLamanense from "../../services/futurolamanenseApi";
import CurrencyFormat from "react-currency-format";

class InformacionCrediticiasForm extends React.Component {

    state = {
        agencias: [],
    }

    componentDidMount() {
        this.getAgencias();
    }

    getAgencias = async () => {
        try {
            const data = await apiFuturoLamanense.organizaciones.getoficina();
            this.setState({ agencias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    errorMontoMaximo = () => {
        if (this.props.montomaximoerror == 'true') {
            return (
                <React.Fragment>
                    <small className="text-danger">El monto máximo supera los $20.000</small>
                </React.Fragment>
            )
        } else if (this.props.formcrediticios.tipocredito == 'CONSUMO' && (+this.props.formcrediticios.monto > 20000)) {
            return (
                <React.Fragment>
                    <small className="text-danger">El monto máximo supera los $20.000</small>
                </React.Fragment>
            )
        }
    }

    render() {
        const { handleChangeCrediticios, formcrediticios } = this.props;
        return (
            <div>
                <div className=" nav-ss p-2 mb-2">
                    <b >Datos del Crédito</b>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-floating mb-3 ">
                            <select className="form-select " onChange={handleChangeCrediticios} name="tipocredito" value={formcrediticios.tipocredito} required>
                                <option value="" className="seleccion-inicio">Seleccione el tipo de crédito...</option>
                                <option value="MICROCREDITO">MICROCREDITO</option>
                                <option value="CONSUMO">CONSUMO</option>
                                <option value="VIVIENDA">VIVIENDA</option>
                            </select>
                            <label htmlFor="floatingSelect">Tipo crédito <span className="color-require">*</span></label>
                        </div>

                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <select className="form-select" onChange={handleChangeCrediticios} name="tipotabla" value={formcrediticios.tipotabla} required>
                                <option value="" className="seleccion-inicio">Seleccione el tipo de tabla... </option>
                                <option value="Francesa" >FRANCESA </option>
                                <option value="Alemana" >ALEMANA </option>
                            </select>
                            <label htmlFor="floatingSelect">Tipo tabla <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <div className="form-floating">
                            <CurrencyFormat type="text" className="form-control" onChange={handleChangeCrediticios} name="monto" value={formcrediticios.monto} required />
                            <label htmlFor="floatingInput">Monto <span className="color-require">*</span></label>
                        </div>
                        {this.errorMontoMaximo()}
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" onChange={handleChangeCrediticios} name="plazomeses" value={formcrediticios.plazomeses} required />
                            <label htmlFor="floatingInput">Plazo en meses <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <select className="form-select" onChange={handleChangeCrediticios} name="agencia" value={formcrediticios.agencia} required>
                                <option value="" className="seleccion-inicio">Seleccione la agencia... </option>
                                {
                                    this.state.agencias.map((data, index) => {
                                        return (
                                            <option value={data.secuencialDivision} key={index + 1} >
                                                {data.ciudad}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            <label htmlFor="floatingSelect">Agencia <span className="color-require">*</span></label>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default InformacionCrediticiasForm;