export default class Persona {

    constructor(
        idpersona,
        tipoidentificacion,
        identificacion,
        codigodactilar,
        nombres,
        apellidos,
        fechanacimiento,
        sexo,
        estadocivil,
        paisnacimiento,
        email,
        cargasfamiliares,
        tipoeducacion,
        profesion,
        actividadeconomica,
        etnia,
        idprovincia,
        idcanton,
        idsector,
        codigorecinto,
        tipovivienda,
        celular,
        direcciondomicilio,
        referenciadomicilio,
        essocio,
        fechacreacion,
        ingresosueldo,
        ingresonegocio,
        ingresopensiones,
        ingresootros,
        egresoalimentacion,
        egresoarriendos,
        egresoeducacion,
        egresosalud,
        egresoserviciosbasicos,
        egresotransporte
    ) {
        this.idpersona = idpersona;
        this.tipoidentificacion = tipoidentificacion;
        this.identificacion = identificacion;
        this.codigodactilar = codigodactilar;
        this.nombres = nombres;
        this.apellidos = apellidos;
        this.fechanacimiento = fechanacimiento;
        this.sexo = sexo;
        this.estadocivil = estadocivil;
        this.paisnacimiento = paisnacimiento;
        this.email = email;
        this.cargasfamiliares = cargasfamiliares;
        this.tipoeducacion = tipoeducacion;
        this.profesion = profesion;
        this.actividadeconomica = actividadeconomica;
        this.etnia = etnia;
        this.idprovincia = idprovincia;
        this.idcanton = idcanton;
        this.idsector = idsector;
        this.codigorecinto = codigorecinto;
        this.tipovivienda = tipovivienda;
        this.celular = celular;
        this.direcciondomicilio = direcciondomicilio;
        this.referenciadomicilio = referenciadomicilio;
        this.essocio = essocio;
        this.fechacreacion = fechacreacion;
        this.ingresosueldo = ingresosueldo;
        this.ingresonegocio = ingresonegocio;
        this.ingresopensiones = ingresopensiones;
        this.ingresootros = ingresootros;
        this.egresoalimentacion = egresoalimentacion;
        this.egresoarriendos = egresoarriendos;
        this.egresoeducacion = egresoeducacion;
        this.egresosalud = egresosalud;
        this.egresoserviciosbasicos = egresoserviciosbasicos;
        this.egresotransporte = egresotransporte;
    }
}