import React from "react";
import '../../App.css';
import CurrencyFormat from 'react-currency-format';

class InformacionLaboralForm extends React.Component {

    state = {
        formFile: {
            'inputFile': '',
        }
    }

    render() {
        const { handleChangeLaboral, formdatoslaboral } = this.props
        return (
            <div>
                <br />
                <div className=" nav-ss p-2 mb-2">
                    <b >Datos Laborales</b>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <select className="form-select" id="floatingSelect" onChange={handleChangeLaboral} name="tipodependencia" value={formdatoslaboral.tipodependencia} required>
                                <option value="" className="seleccion-inicio">Seleccione la dependencia...</option>
                                <option value="Dependiente">Dependiente</option>
                                <option value="Independiente">Independiente</option>
                            </select>
                            <label htmlFor="floatingSelect">Tipo de dependencia <span className="color-require">*</span></label>
                        </div>
                        
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" onChange={handleChangeLaboral} name="nombre" value={formdatoslaboral.nombre} required />
                            <label htmlFor="floatingInput">Nombre de la empresa o negocio <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" onChange={handleChangeLaboral} name="direccion" value={formdatoslaboral.direccion} required />
                            <label htmlFor="floatingInput">Dirección <span className="color-require">*</span></label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" pattern="[0-9]+" maxLength="2" minLength="1" min="1" onChange={handleChangeLaboral} name="tiempo" value={formdatoslaboral.tiempo} required />
                            <label htmlFor="floatingInput">Tiempo de trabajo en años <span className="color-require">*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-floating mb-3">
                            <CurrencyFormat  type="text" className="form-control" onChange={handleChangeLaboral} name="ingresos" value={formdatoslaboral.ingresos} required />
                            <label htmlFor="floatingInput">Sueldo /Ingresos de negocio <span className="color-require">*</span></label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InformacionLaboralForm;