import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../withRouter";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbarpages from "../navbar-pages";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { isMobile } from 'react-device-detect';
import apiJustificacion from "../../services/justificacionApi";
import apiCreditoOnline from "../../services/creditoonlineApi";

class LayoutConsultaSolicitud extends React.Component {

    constructor() {
        super()
    }

    state = {
        persona: {},
        solicitudes: [],
        puedeAgregarNuevaSolicitud: false,
        open: false,
        justificaciones: [],
        formsolicitudcredito: {
            'idsolicitud': 0,
            'tipocredito': '',
            'monto': '',
            'plazomeses': 12,
            'destino': '',
            'idpersona': 0,
            'estado': 'PENDIENTE',
            'agencia': '',
            'tipodependencia': '',
            'nombre': '',
            'direccion': '',
            'tiempo': '',
            'ingresos': '',
            'valorcuotacalculada': '',
            'fecha': '',
            'asesor': ''
        },
        nombreagencia: '',
        motivocancelacion: ''
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        if (sessionStorage.getItem('identificacion') == '' || sessionStorage.getItem('identificacion') == null) {
            this.props.navigate('/')
        } else {
            this.getDataPersona()
        }
    }

    verificarUltimaSolicitud = async (idpersona) => {
        try {
            const data = await apiCreditoOnline.solicitudes.verificarSiTieneUltimaSolicitudDentroDelRangoFecha(idpersona);
            if (this.diferenciaFechas(data.response) < 31) {
                this.setState({ puedeAgregarNuevaSolicitud: false });
            } else {
                this.setState({ puedeAgregarNuevaSolicitud: true });
            }
        } catch (error) {
            console.log(error)
        }
    }

    diferenciaFechas(fecha) {
        let fecha1 = new Date(fecha);
        let fecha2 = new Date();
        let resta = fecha2.getTime() - fecha1.getTime()
        return Math.round(resta / (1000 * 60 * 60 * 24))
    }

    getDataPersona = async () => {
        try {
            const data = await apiCreditoOnline.personas.getpersonaidentificacion(sessionStorage.getItem('identificacion'));
            this.setState({ persona: data.response });
            this.getSolicitudes(data.response.idpersona);
            this.verificarUltimaSolicitud(data.response.idpersona);
        } catch (error) {
            console.log(error);
        }
    }

    getSolicitudes = async (idpersona) => {
        try {
            const data = await apiCreditoOnline.solicitudes.solicitudesporpersona(idpersona);
            if (data.response) {
                this.setState({ solicitudes: data.response });
            }
        } catch (error) {
            console.log(error);
        }
    }

    confirmacionCancelarSolicitud = async (idsolicitud) => {
        confirmAlert({
            title: 'Confirmación',
            message: '¿Estas seguro de cancelar esta solicitud?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.cancelarSolicitud(idsolicitud)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    cancelarSolicitud = async (idsolicitud) => {
        try {
            await apiCreditoOnline.solicitudes.cancelarsolicitud(idsolicitud);
            this.getSolicitudes(this.state.persona.idpersona);
            this.verificarUltimaSolicitud(this.state.persona.idpersona);
            toast.success("Tu solicitud se a cancelado con exito!!");
        } catch (error) {
            console.log(error);
        }
    }

    botonCancelar = (idsolicitud, estado) => {
        if (estado == 'PENDIENTE') {
            return (
                <button className="btn btn-outline-danger btn-sm" onClick={() => this.confirmacionCancelarSolicitud(idsolicitud)}>
                    <i className="fas fa-window-close"></i> Cancelar solicitud
                </button>
            )
        }
    }

    btnAgregarNuevaSolicitud = () => {
        if (this.state.puedeAgregarNuevaSolicitud == true) {
            if (!isMobile) {
                return (
                    <Link to={'/agregar-solicitud'} className="btn btn-primary mt-0 mb-2 me-3 ">
                        <i className="fas fa-plus"></i> Agregar nueva solicitud
                    </Link>
                )
            } else {
                return (
                    <Link to={'/agregar-solicitud'} className="btn btn-outline-primary ">
                        <small>Agregar solicitud</small>
                    </Link>
                )
            }

        }
    }

    onOpenModal = (data) => {
        this.setState({ open: true, formsolicitudcredito: data });
        this.getAgenciaOne(data.agencia);
        this.getJustificaciones(data.idsolicitud);
    };

    getAgenciaOne = async (idagencia) => {
        try {
            const data = await apiCreditoOnline.solicitudes.agenciaone(idagencia);
            this.setState({ nombreagencia: data.response.agencia });
        } catch (error) {
            console.log(error);
        }
    }

    getJustificaciones = async (idsolicitud) => {
        try {
            const data = await apiJustificacion.justificacion.getJustificaciones(idsolicitud);
            this.setState({ justificaciones: data.response });
        } catch (error) {
            console.log(error);
        }
    }

    getmotivoCancelacion = async (idsolicitud) => {
        try {
            const data = await apiCreditoOnline.motivocancelacion.getmotivocancelacion(idsolicitud);
            this.setState({ motivocancelacion: data.response.motivo });
        } catch (error) {
            console.log(error);
        }
    }

    verificarEstado = (estado, idsolicitud) => {
        if (estado == 'CANCELADO') {
            return (
                <React.Fragment>
                    <span className="text-danger">Solicitud Negada</span>
                    <p className="text-primary mb-0 pb-0 mt-1">
                        <a className="btn btn-sm btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#modalMotivoCancelacion" onClick={() => this.getmotivoCancelacion(idsolicitud)}>
                            Ver Detalle
                        </a>
                    </p>

                    <div className="modal fade" id="modalMotivoCancelacion" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Motivo</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body text-center">
                                    <h5>{this.state.motivocancelacion}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
        } else if (estado == 'PENDIENTE') {
            return (
                <React.Fragment>
                    <div className="text-secondary">
                        PENDIENTE
                    </div>
                </React.Fragment>
            )
        } else if (estado == 'EN PROCESO') {
            return (
                <React.Fragment>
                    <div className="text-primary">
                        EN PROCESO
                    </div>
                </React.Fragment>
            )
        } else if (estado == 'PRE-APROBADO') {
            return (
                <React.Fragment>
                    <div className="text-primary">
                        PRE-APROBADO
                    </div>
                </React.Fragment>
            )
        }
    }

    isMobileOrPC = () => {
        if (!isMobile) {
            return (
                <React.Fragment>
                    <h5 className="pb-3 border-bottom border-primary text-primary mb-3">
                        <b>SOLICITUDES DE CRÉDITO EN LÍNEA</b>
                    </h5>
                    <br />

                    <div className="row">
                        <div className="col-lg-6 pt-2">
                            <h5 className="mb-0 pb-0 text-secondary">
                                <i className="fas fa-id-card-alt"></i> {this.state.persona.nombres} {this.state.persona.apellidos} - {this.state.persona.identificacion}
                            </h5>
                        </div>
                        <div className="col-lg-6 text-end pb-0">
                            <Link to={'/mis-datos'} type="button" className="btn btn-primary mt-0 mb-2 me-3">
                                <small><i className="far fa-address-card"></i> Mis Datos</small>
                            </Link>
                            {this.btnAgregarNuevaSolicitud()}
                            <Link to={'/'} type="button" className="btn btn-danger mt-0 mb-2">
                                <i className="fas fa-times"></i> Salir
                            </Link>
                        </div>
                    </div>

                    <div className="bg-success p-2 rounded-top mt-0 text-white">
                        <h6 className="mb-0">Mis solicitudes de crédito</h6>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-striped table-xl">
                            <thead className=" table-success">
                                <tr className=" ">
                                    <th scope="col">#</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Tipo crédito</th>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Plazo</th>
                                    <th scope="col">Destino</th>
                                    <th scope="col" className="text-center">Estado</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.solicitudes.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data.idsolicitud}</td>
                                                <td>{data.fecha}</td>
                                                <td>{data.tipocredito}</td>
                                                <td>{data.monto}</td>
                                                <td>{data.plazomeses}</td>
                                                <td><small>{data.destino}</small></td>
                                                <td className="text-center"> {this.verificarEstado(data.estado, data.idsolicitud)}</td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm me-3" onClick={() => this.onOpenModal(data)}>
                                                        <i className="fas fa-file-alt"></i>
                                                    </button>
                                                    {this.botonCancelar(data.idsolicitud, data.estado)}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="pb-1 border-bottom border-primary text-primary">
                        <b >SOLICITUDES DE CRÉDITO EN LÍNEA</b>
                    </div>
                    <p className="mt-3"> <i className="fas fa-id-card-alt"></i> {this.state.persona.nombres} - {this.state.persona.identificacion}</p>
                    <div className="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={'/mis-datos'} type="button" className="btn btn-outline-primary">
                            <small>Mis Datos</small>
                        </Link>
                        {this.btnAgregarNuevaSolicitud()}
                        <Link to={'/'} type="button" className="btn btn-outline-danger">
                            Salir
                        </Link>
                    </div>

                    <div className="bg-success p-2 rounded-top mt-0 text-white">
                        <h6 className="mb-0">Mis solicitudes de crédito</h6>
                    </div>

                    {
                        this.state.solicitudes.map((data, index) => {
                            return (
                                <div key={index} className="border rounded mt-1 p-1">
                                    <div className="row" >
                                        <div className="col-6">
                                            <p className="mb-0">
                                                <small><b>ID: </b> {data.idsolicitud}</small>
                                            </p>
                                            <p className="mb-0">
                                                <small><b>Monto: </b> ${data.monto}</small>
                                            </p>
                                        </div>
                                        <div className="col-6 p-0">
                                            <p className="mb-0 text-secondary">
                                                <small><b>{data.tipocredito}</b></small>
                                            </p>
                                            <p className="mb-0">
                                                <small><b>Plazo: </b> {data.plazomeses} meses</small>
                                            </p>
                                        </div>
                                        <div className="col-12 ">
                                            <p className="mb-0">
                                                <small><b>Destino: </b> {data.destino}</small>
                                            </p>
                                            <p className="mb-0">
                                                <small><b>Fecha: </b> {data.fecha}</small>
                                            </p>
                                            <p className="mb-0">
                                                <small><b>Estado: </b> {data.estado}</small>
                                            </p>
                                            <div className="text-end">
                                                <button className="btn btn-primary btn-sm me-3" onClick={() => this.onOpenModal(data)}>
                                                    <i className="fas fa-file-alt"></i>
                                                </button>
                                                {this.botonCancelar(data.idsolicitud, data.estado)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </React.Fragment>
            )
        }
    }


    render() {
        const { open } = this.state;
        return (
            <React.Fragment>
                <ToastContainer />
                <Navbarpages />

                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/'}>Inicio</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Consulta de solicitudes de crédito</li>
                        </ol>
                    </nav>

                    {this.isMobileOrPC()}

                    <br />
                </div>

                <Modal open={open} onClose={this.onCloseModal} center w
                    classNames={{
                        overlayAnimationIn: 'customEnterOverlayAnimation',
                        overlayAnimationOut: 'customLeaveOverlayAnimation',
                        modalAnimationIn: 'customEnterModalAnimation',
                        modalAnimationOut: 'customLeaveModalAnimation',
                        modal: 'modalws'
                    }}
                    animationDuration={800}>
                    <h2>Solicitud de Crédito #{this.state.formsolicitudcredito.idsolicitud}</h2>
                    <div className="container border-top w-100">

                        <div className="row">
                            <div className="col-lg-6 pt-2">
                                <b>Fecha</b> {this.state.formsolicitudcredito.fecha}
                            </div>
                            <div className="col-lg-6 pt-2">
                                <b>Estado</b> {this.state.formsolicitudcredito.estado}
                            </div>
                        </div>
                        <div className=" bg-light nav-ss p-2 mb-2 mt-2">
                            <b >Datos Personales</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <b>Nombres </b> {this.state.persona.nombres}
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Celular </b> {this.state.persona.celular}
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Email </b> {this.state.persona.email}
                            </div>
                        </div>
                        <div className=" bg-light nav-ss p-2 mb-2 mt-2">
                            <b >Datos Laborales</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <b>Tipo dependencia </b> {this.state.formsolicitudcredito.tipodependencia}
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Empresa o negocio </b> {this.state.formsolicitudcredito.nombre}
                            </div>
                            <div className="col-lg-12 mb-2">
                                <b>Dirección </b> {this.state.formsolicitudcredito.direccion}
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Tiempo en trabajo </b> {this.state.formsolicitudcredito.tiempo} años
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Ingresos </b> ${this.state.formsolicitudcredito.ingresos}
                            </div>
                        </div>

                        <div className="bg-light nav-ss p-2 mb-2 mt-1">
                            <b >Datos del Crédito</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <b>Tipo crédito </b> {this.state.formsolicitudcredito.tipocredito}
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Monto </b> ${this.state.formsolicitudcredito.monto}
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Plazo </b> {this.state.formsolicitudcredito.plazomeses} meses
                            </div>
                            <div className="col-lg-6 mb-2">
                                <b>Valor cuota calculada </b> ${this.state.formsolicitudcredito.valorcuotacalculada}
                            </div>
                            <div className="col-lg-12 mb-2">
                                <b>Agencia </b> {this.state.nombreagencia}
                            </div>
                            <div className="col-lg-12 mb-2">
                                <b>Destino de fondos </b> {this.state.formsolicitudcredito.destino}
                            </div>
                        </div>
                        <div className="bg-light nav-ss p-2 mb-2 mt-0">
                            <b >Justificación de ingresos</b>
                        </div>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Documento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.justificaciones.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{data.descripcion}</td>
                                                    <td><a href={'https://sistemflm.futurolamanense.fin.ec/justificacionlaboralimagen/' + data.imagen} target="_blank">{data.imagen}</a></td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        <br />
                    </div>
                </Modal>


            </React.Fragment>
        )
    }
}

export default withRouter(LayoutConsultaSolicitud);