import Global from "./urlGlobal";
const BASEE = Global.UrlGlobal.urlApiFuturoLamanense;

async function callApidatos(endpoint, options) {
    const url = BASEE + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
}

const apiFuturoLamanense = {
    generales: {
        getpaises() {
            return callApidatos(`/generales/paises`);
        },
        getactividadeseconomicas() {
            return callApidatos(`/generales/actividadeseconomicas`);
        },
        getprovincias() {
            return callApidatos(`/generales/division-politica-provincias`);
        },
        getcantones(secuencialDivisionPadre) {
            return callApidatos(`/generales/division-politica-cantones-provincias/`+ secuencialDivisionPadre);
        },
        getParroquias(secuencialDivisionPadre) {
            return callApidatos(`/generales/recintos/`+ secuencialDivisionPadre);
        },
        getRecintosSector(secuencial) {
            return callApidatos(`/generales/recintos-sector/`+ secuencial);
        },
    },
    persona: {
        gettipoeducacion() {
            return callApidatos(`/persona/tipoeducacion`)
        },
        getprofesiones() {
            return callApidatos(`/persona/profesiones`)
        },
        getetnias() {
            return callApidatos(`/persona/etnias`)
        },
        gettipovivienda() {
            return callApidatos(`/persona/tipovivienda`)
        },
        getestadocivil() {
            return callApidatos(`/persona/estadocivil`)
        }
    },
    organizaciones: {
        getoficina() {
            return callApidatos(`/organizaciones/oficina`)
        },
    }
};

export default apiFuturoLamanense;