import React from "react";
import { Link } from "react-router-dom";

class HeadFormulario extends React.Component {

    viewStep = () => {
        if (this.props.aplica == '1') {
            if (this.props.step == 1) {
                return (
                    <React.Fragment>
                        <ol className="stepper">
                            <li className="active"></li>
                            <li ></li>
                            <li></li>
                        </ol>
                    </React.Fragment>
                )
            }
            if (this.props.step == 2) {
                return (
                    <React.Fragment>
                        <ol className="stepper">
                            <li ></li>
                            <li className="active"></li>
                            <li></li>
                        </ol>
                    </React.Fragment>
                )
            }
            if (this.props.step == 3) {
                return (
                    <React.Fragment>
                        <ol className="stepper">
                            <li ></li>
                            <li ></li>
                            <li className="active"></li>
                        </ol>
                    </React.Fragment>
                )
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-5">
                        <h5 className="text-secondary"> SOLICITUD DE CRÉDITO EN LÍNEA</h5>
                        <nav aria-label="breadcrumb mb-0 pb-0">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}><i className="fas fa-home"></i> Inicio</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Formulario solicitud de crédito</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-7">
                        {this.viewStep()}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default HeadFormulario;