import React from "react";
import Logo from "./../imagenes/logohorizont.svg";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class Navbarpages extends React.Component {

    headMovile = () => {
        if (!isMobile) {
            return (
                <React.Fragment>
                    <div className="container-fluid  m-0 border-bottom bg-light">
                        <div className='row'>
                            <div className='col-lg-4 pt-0'>
                                <img src={Logo} className="logo p-0 m-0" />
                            </div>
                            <div className='col-lg-8 text-end pt-4'>
                                <Link to={'/simulador-credito'} className='btn btn-simulador rounded-pill ps-4 pe-4 me-3'>
                                    Simulador de Crédito
                                </Link>
                            </div>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="container-fluid m-0 border-bottom bg-light p-0">
                        <img src={Logo} className="p-0 m-0 w-50" />
                    </div>
                    <br />
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <div>
                {this.headMovile()}
            </div>
        )
    }
}

export default Navbarpages;