import React from "react";
import InformacionLaboralForm from "./informacion-laboral-form";
import '../../App.css';
import InformacionCrediticiasForm from "./informacion-crediticias-form";
import IngresosyEgresos from "./ingresosyegresos";
import apiSimulador from "../../services/simuladorApi";
import urlGlobal from "../../services/urlGlobal";
import ResultadoNoAplica from "./resultado-no-aplica";
import HeadFormulario from "./head-formulario";
import JustificacionesForm from "./justificaciones-form";
import SweetAlert2 from "react-sweetalert2";
import InformacionPersonaForm from "./informacion-persona-form";
import apiFuturoLamanense from "../../services/futurolamanenseApi";
import apiCreditoOnline from "../../services/creditoonlineApi";

class Forms extends React.Component {

    state = {
        step: 1,
        formcrediticios: {
            'tipocredito': '',
            'monto': '',
            'plazomeses': 12,
            'destino': '',
            'idpersona': 0,
            'estado': 'PENDIENTE',
            'agencia': '',
            'tipotabla': ''
        },
        formdatoslaboral: {
            'tipodependencia': '',
            'nombre': '',
            'direccion': '',
            'tiempo': '',
            'ingresos': '',
        },
        formFile: {
            'inputFile': '',
        },
        archivoExtension: true,
        formjustificacion: {
            'imagen': '',
            'file': '',
            'descripcion': ''
        },
        archivosJustificacion: [],
        formingresos: {
            'sueldosalario': '0',
            'ingresonegocio': '',
            'pensiones': '',
            'otros': ''
        },
        formegresos: {
            'alimentacion': '',
            'arriendos': '',
            'educacion': '',
            'salud': '',
            'serviciosbasicos': '',
            'transporte': ''
        },
        valorcuotacalculada: 0,
        totalIngresos: 0,
        totalEgresos: 0,
        swal: {},
        campovacio1: '',
        formpersona: {
            'idpersona': 0,
            'tipoidentificacion': '',
            'identificacion': '',
            'codigodactilar': '',
            'nombres': '',
            'apellidos': '',
            'fechanacimiento': '',
            'sexo': '',
            'estadocivil': '',
            'paisnacimiento': '',
            'email': '',
            'cargasfamiliares': 0,
            'tipoeducacion': '',
            'profesion': '',
            'actividadeconomica': '',
            'etnia': '',
            'idprovincia': '',
            'idcanton': '',
            'idsector': '',
            'codigorecinto': '',
            'tipovivienda': '',
            'celular': '',
            'direcciondomicilio': '',
            'referenciadomicilio': '',
            'essocio': 0,
        },
        cantones: [],
        parroquias: [],
        recintossector: [],
        formautorizacion: {
            'check1': true,
            'check2': true
        },
        datasimulador: {},
        montomaximoerror: 'false'
    }

    recopilarDATOS = (
        codigoIndividualDactilar,
        nombres,
        apellidos,
        esMasculino,
        estadocivil,
        codigoPaisOrigen,
        fechaNacimiento,
        email,
        cargasfamiliares,
        codigoTipoEducacion,
        codigoProfesion,
        codigoActividadEconomica,
        codigoTipoEtnia,
        numeroTelefono,
        idprovincia,
        idcanton,
        idsector,
        codigorecinto,
        tipovivienda,
        direcciondomicilio,
        referenciaDomiciliaria
    ) => {
        this.state.formpersona.codigodactilar = codigoIndividualDactilar;
        this.state.formpersona.nombres = nombres;
        this.state.formpersona.apellidos = apellidos;
        if (esMasculino == true) {
            this.state.formpersona.sexo = 1
        } else {
            this.state.formpersona.sexo = 0
        }
        this.state.formpersona.estadocivil = estadocivil;
        this.state.formpersona.paisnacimiento = codigoPaisOrigen;
        let fecha = fechaNacimiento.split("T")
        this.state.formpersona.fechanacimiento = fecha[0];
        this.state.formpersona.email = email;
        this.state.formpersona.cargasfamiliares = cargasfamiliares;
        this.state.formpersona.tipoeducacion = codigoTipoEducacion;
        this.state.formpersona.profesion = codigoProfesion;
        this.state.formpersona.actividadeconomica = codigoActividadEconomica;
        this.state.formpersona.etnia = codigoTipoEtnia;
        this.state.formpersona.celular = numeroTelefono;
        this.state.formpersona.idprovincia = idprovincia;
        this.getCantones(idprovincia);
        this.state.formpersona.idcanton = idcanton;
        this.getParroquias(idcanton);
        this.state.formpersona.idsector = idsector;
        this.getRecintoSector(idsector);
        this.state.formpersona.codigorecinto = codigorecinto;
        this.state.formpersona.tipovivienda = tipovivienda;
        this.state.formpersona.direcciondomicilio = direcciondomicilio;
        this.state.formpersona.referenciadomicilio = referenciaDomiciliaria;
    }

    componentDidMount() {
        if (sessionStorage.getItem('identificacion') == '' || sessionStorage.getItem('identificacion') == null) {
            this.props.navigate('/')
        } else {
            this.setState({
                formpersona: {
                    ... this.state.formpersona,
                    identificacion: sessionStorage.getItem('identificacion'),
                    tipoidentificacion: sessionStorage.getItem('tipoidentificacion'),
                }
            });
            setTimeout(() => {
                this.verificarSiExistenDatosPersonaMysql();
                this.verificarSiEsSocio();
            }, 600)
        }
    }

    verificarSiEsSocio = async () => {
        try {
            const data = await apiCreditoOnline.personas.getVerificarSiEsSocio(sessionStorage.getItem('identificacion'));
            if (data.response > 0) {
                this.state.formpersona.essocio = 1;
            } else {
                this.state.formpersona.essocio = 0
            }
        } catch (error) {
            console.log(error);
        }
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    nuevamente = () => {
        this.setState({
            step: 1
        })
    }

    handleChangeEngresos = (e) => {
        this.setState({
            formegresos: {
                ... this.state.formegresos,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleChangeIngresos = (e) => {
        this.setState({
            formingresos: {
                ... this.state.formingresos,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleChangeCrediticios = (e) => {
        this.setState({
            formcrediticios: {
                ... this.state.formcrediticios,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleChangeLaboral = (e) => {
        this.setState({
            formdatoslaboral: {
                ... this.state.formdatoslaboral,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleChangeJustificacion = (e) => {
        this.setState({
            formjustificacion: {
                ... this.state.formjustificacion,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleChangePersona = (e) => {
        if (e.target.name === 'idprovincia') {
            this.getCantones(e.target.value);
        }
        if (e.target.name === 'idcanton') {
            this.getParroquias(e.target.value);
        }
        if (e.target.name === 'idsector') {
            this.getRecintoSector(e.target.value);
        }
        this.setState({
            formpersona: {
                ... this.state.formpersona,
                [e.target.name]: e.target.value,
            }
        })
    }

    getCantones = async (secuencialDivisionPadre) => {
        try {
            const data = await apiFuturoLamanense.generales.getcantones(secuencialDivisionPadre);
            this.setState({ cantones: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getParroquias = async (secuencialDivisionPadre) => {
        try {
            const data = await apiFuturoLamanense.generales.getParroquias(secuencialDivisionPadre);
            this.setState({ parroquias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getRecintoSector = async (secuencialDivisionPolitica) => {
        try {
            const data = await apiFuturoLamanense.generales.getRecintosSector(secuencialDivisionPolitica);
            this.setState({ recintossector: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    agregarJustificacion = () => {
        if (this.state.formjustificacion.descripcion != '' && this.state.formjustificacion.imagen != '') {
            this.state.archivosJustificacion.push(this.state.formjustificacion);
            this.setState({ state: this.state });
            this.setState({
                formjustificacion: {
                    'imagen': '',
                    'file': '',
                    'descripcion': ''
                },
            })
        } else if (this.state.formjustificacion.imagen == '') {
            this.setState({
                swal: {
                    show: true,
                    title: 'Error',
                    text: "Tienes que subir el documento de justificación",
                    icon: 'error',
                },
            });
        } else if (this.state.formjustificacion.descripcion == '') {
            this.setState({
                swal: {
                    show: true,
                    title: 'Error',
                    text: "Tienes que ingresar la descripción del documento",
                    icon: 'error',
                },
            });
        }
    }

    eliminarFilaJustificacion = (pocision) => {
        this.state.archivosJustificacion.splice(pocision, 1);
        this.setState({ state: this.state });
    }

    procesarFiltro1 = (e) => {
        e.preventDefault();
        this.calcularTablaPresuntiva();
    }

    calcularTablaPresuntiva = () => {
        if (this.state.formcrediticios.tipocredito == 'MICROCREDITO') {
            this.consultarCuotas('015', 7, '0402');
        } else if (this.state.formcrediticios.tipocredito == 'CONSUMO') {
            this.consultarCuotas('014', 5, '0203');
        } else if (this.state.formcrediticios.tipocredito == 'VIVIENDA') {
            this.consultarCuotas('010', 6, '0301');
        }
    }

    quitarcoma(monto) {
        var montoFormat = monto.replace(/[,]/g, '');
        return montoFormat
    }

    consultarCuotas = async (codigoprestamo, segmentointerno, subcalificacion) => {
        try {
            const data = await apiSimulador.simulador.consultarCredito(codigoprestamo, '1', segmentointerno, subcalificacion, urlGlobal.fechaActual(), +this.quitarcoma(this.state.formcrediticios.monto), 5, this.state.formcrediticios.plazomeses, 30);
            if (data.result.DevuelveTablaPresuntivaParaSitioResult.EsRespuestaCorrecta == 'true') {
                this.setState({ montomaximoerror: 'false' });
                this.setState({ valorcuotacalculada: data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla.TablaPagos_PorCuotas[0].ValorTotal, datasimulador: data });
                this.calcularValores(data.result.DevuelveTablaPresuntivaParaSitioResult.DevuelveTablaPresuntivaParaImpresionMS.ItemsTabla.TablaPagos_PorCuotas[0].ValorTotal);
            } else {
                this.setState({ montomaximoerror: 'true' });
            }
        } catch (error) {
            console.log(error);
        }
    }

    calcularValores = async (valorcuota) => {
        var sumaIngresos = (+ this.state.formdatoslaboral.ingresos) + (+this.verificarSivalorVacio(this.state.formingresos.ingresonegocio)) + (+this.verificarSivalorVacio(this.state.formingresos.pensiones)) + (+this.verificarSivalorVacio(this.state.formingresos.otros));
        var sumaEgresos = (+ this.state.formegresos.alimentacion) + (+ this.verificarSivalorVacio(this.state.formegresos.arriendos)) + (+ this.state.formegresos.educacion) + (+ this.state.formegresos.salud) + (+ this.state.formegresos.serviciosbasicos) + (+ this.state.formegresos.transporte);
        //console.log('ingresos ' + sumaIngresos)
        //console.log('egresos ' + sumaEgresos)
        //console.log(valorcuota)
        sumaEgresos = sumaEgresos + (+ valorcuota);

        //console.log('egresos 2 ' + sumaEgresos)

        this.setState({ totalIngresos: sumaEgresos, totalEgresos: sumaEgresos })
        var porcentajeIngresoCalculo = sumaIngresos * 0.70
        //console.log('70% ' + porcentajeIngresoCalculo)

        if (porcentajeIngresoCalculo > sumaEgresos) {
            this.nextStep()
        } else {
            this.setState({
                step: 10
            })
        }
    }

    verificarSivalorVacio(str) {
        if (str == '') {
            return 0
        } else {
            return str
        }
    }

    procesarFiltro2 = () => {
        if (this.state.formcrediticios.destino == '') {
            this.setState({
                swal: {
                    show: true,
                    title: 'Error',
                    text: "Tienes que describir para que necesitas el crédito",
                    icon: 'error',
                },
                campovacio1: 'true'
            });
            this.cerrarSwal();
        } else if (this.state.archivosJustificacion.length < 1 || this.state.archivosJustificacion.length == null) {
            this.setState({
                swal: {
                    show: true,
                    title: 'Error',
                    text: "Tienes que subir documentos que justifiquen tus ingresos",
                    icon: 'error',
                },
                campovacio1: 'false'
            });
            this.cerrarSwal();
        } else {
            this.nextStep()
        }
    }

    cerrarSwal = () => {
        setTimeout(() => {
            this.setState({
                swal: {
                    show: false,
                },
            });
        }, 3000)
    }

    handleChangeAutorizacion = (e) => {
        this.setState({
            formautorizacion: {
                ... this.state.formautorizacion,
                [e.target.name]: e.target.value,
            }
        })
    }

    guardarSolicitud = async (e) => {
        e.preventDefault();

        var dataCreate = {
            "idsolicitud": 0,
            "tipocredito": this.state.formcrediticios.tipocredito,
            "monto": +this.state.formcrediticios.monto,
            "plazomeses": +this.state.formcrediticios.plazomeses,
            "destino": this.state.formcrediticios.destino,
            "idpersona": 0,
            "estado": this.state.formcrediticios.estado,
            "agencia": +this.state.formcrediticios.agencia,
            "tipodependencia": this.state.formdatoslaboral.tipodependencia,
            "nombre": this.state.formdatoslaboral.nombre,
            "direccion": this.state.formdatoslaboral.direccion,
            "tiempo": +this.state.formdatoslaboral.tiempo,
            "ingresos": +this.state.formdatoslaboral.ingresos,
            "valorcuotacalculada": +this.state.valorcuotacalculada,
            "fecha": "",
            "asesor": 0,
            "tipotabla": this.state.formcrediticios.tipotabla,
            "justificaciones": this.state.archivosJustificacion,
            "persona": {
                "idpersona": 0,
                "tipoidentificacion": this.state.formpersona.tipoidentificacion,
                "identificacion": this.state.formpersona.identificacion,
                "codigodactilar": this.state.formpersona.codigodactilar,
                "nombres": this.state.formpersona.nombres,
                "apellidos": this.state.formpersona.apellidos,
                "fechanacimiento": this.state.formpersona.fechanacimiento,
                "sexo": +this.state.formpersona.sexo,
                "estadocivil": this.state.formpersona.estadocivil,
                "paisnacimiento": this.state.formpersona.paisnacimiento,
                "email": this.state.formpersona.email,
                "cargasfamiliares": +this.state.formpersona.cargasfamiliares,
                "tipoeducacion": this.state.formpersona.tipoeducacion,
                "profesion": this.state.formpersona.profesion,
                "actividadeconomica": this.state.formpersona.actividadeconomica,
                "etnia": this.state.formpersona.etnia,
                "idprovincia": +this.state.formpersona.idprovincia,
                "idcanton": +this.state.formpersona.idcanton,
                "idsector": +this.state.formpersona.idsector,
                "codigorecinto": this.state.formpersona.codigorecinto,
                "tipovivienda": this.state.formpersona.tipovivienda,
                "celular": this.state.formpersona.celular,
                "direcciondomicilio": this.state.formpersona.direcciondomicilio,
                "referenciadomicilio": this.state.formpersona.referenciadomicilio,
                "essocio": this.state.formpersona.essocio,
                "fechacreacion": "",
                "ingresosueldo": +this.verificarSivalorVacio(this.state.formdatoslaboral.ingresos),
                "ingresonegocio": +this.verificarSivalorVacio(this.state.formingresos.ingresonegocio),
                "ingresopensiones": +this.verificarSivalorVacio(this.state.formingresos.pensiones),
                "ingresootros": +this.verificarSivalorVacio(this.state.formingresos.otros),
                "egresoalimentacion": +this.state.formegresos.alimentacion,
                "egresoarriendos": +this.verificarSivalorVacio(this.state.formegresos.arriendos),
                "egresoeducacion": +this.state.formegresos.educacion,
                "egresosalud": +this.state.formegresos.salud,
                "egresoserviciosbasicos": +this.state.formegresos.serviciosbasicos,
                "egresotransporte": +this.state.formegresos.transporte
            }
        }
        try {
            const data = await apiCreditoOnline.solicitudes.create(dataCreate);
            if (data.response) {
                this.setState({
                    swal: {
                        show: true,
                        title: 'Hecho',
                        text: 'Solicitud enviada correctamente!!',
                        icon: 'success',
                    },
                });
                this.limpiarForm();
            } else {
                this.setState({
                    swal: {
                        show: true,
                        title: 'Error',
                        text: JSON.stringify(data),
                        icon: 'error',
                    },
                });
                this.cerrarSwal();
            }
        } catch (error) {
            console.log(error);
        }
    }

    limpiarForm = () => {
        this.setState({
            formpersona: {
                'tipoidentificacion': '',
                'identificacion': '',
                'codigodactilar': '',
                'nombres': '',
                'apellidos': '',
                'fechanacimiento': '',
                'sexo': '',
                'estadocivil': '',
                'paisnacimiento': '',
                'email': '',
                'cargasfamiliares': 0,
                'tipoeducacion': '',
                'profesion': '',
                'actividadeconomica': '',
                'etnia': '',
                'idprovincia': '',
                'idcanton': '',
                'idsector': '',
                'codigorecinto': '',
                'tipovivienda': '',
                'celular': '',
                'direcciondomicilio': '',
                'referenciadomicilio': '',
                'essocio': 0,
            },
            formingresos: {
                'sueldosalario': '0',
                'ingresonegocio': '',
                'pensiones': '',
                'otros': ''
            },
            formegresos: {
                'alimentacion': '',
                'arriendos': '0',
                'educacion': '',
                'salud': '',
                'serviciosbasicos': '',
                'transporte': ''
            },
            formcrediticios: {
                'tipocredito': '',
                'monto': '',
                'plazomeses': 12,
                'destino': '',
                'idpersona': 0,
                'estado': 'PENDIENTE',
                'agencia': '',
                'tipotabla': ''
            },
            formdatoslaboral: {
                'tipodependencia': '',
                'nombre': '',
                'direccion': '',
                'tiempo': 0,
                'ingresos': '',
            },
        });
        this.props.irHaSolicitud()
    }

    verificarSiExistenDatosPersonaMysql = async () => {
        try {
            const data = await apiCreditoOnline.personas.getpersonaidentificacion(sessionStorage.getItem('identificacion'));
            if (data.response) {
                sessionStorage.setItem('isregisterok', "true");
                this.getCantones(data.response.idprovincia);
                this.getParroquias(data.response.idcanton);
                this.getRecintoSector(data.response.idsector);
                this.setState({
                    formpersona: {
                        'idpersona': data.response.idpersona,
                        'tipoidentificacion': data.response.tipoidentificacion,
                        'identificacion': data.response.identificacion,
                        'codigodactilar': data.response.codigodactilar,
                        'nombres': data.response.nombres,
                        'apellidos': data.response.apellidos,
                        'fechanacimiento': data.response.fechanacimiento,
                        'sexo': data.response.sexo,
                        'estadocivil': data.response.estadocivil,
                        'paisnacimiento': data.response.paisnacimiento,
                        'email': data.response.email,
                        'cargasfamiliares': data.response.cargasfamiliares,
                        'tipoeducacion': data.response.tipoeducacion,
                        'profesion': data.response.profesion,
                        'actividadeconomica': data.response.actividadeconomica,
                        'etnia': data.response.etnia,
                        'idprovincia': data.response.idprovincia,
                        'idcanton': data.response.idcanton,
                        'idsector': data.response.idsector,
                        'codigorecinto': data.response.codigorecinto,
                        'tipovivienda': data.response.tipovivienda,
                        'celular': data.response.celular,
                        'direcciondomicilio': data.response.direcciondomicilio,
                        'referenciadomicilio': data.response.referenciadomicilio,
                        'essocio': data.response.essocio,
                    },
                    formingresos: {
                        'sueldosalario': data.response.ingresosueldo,
                        'ingresonegocio': data.response.ingresonegocio,
                        'pensiones': data.response.ingresopensiones,
                        'otros': data.response.ingresootros
                    },
                    formegresos: {
                        'alimentacion': data.response.egresoalimentacion,
                        'arriendos': data.response.egresoarriendos,
                        'educacion': data.response.egresoeducacion,
                        'salud': data.response.egresosalud,
                        'serviciosbasicos': data.response.egresoserviciosbasicos,
                        'transporte': data.response.egresotransporte
                    },
                })
                setTimeout(() => {
                }, 1000)
            } else {
                sessionStorage.setItem('isregisterok', "false");
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        switch (this.state.step) {
            case 1:
                return (
                    <React.Fragment>
                        <SweetAlert2 {...this.state.swal} />
                        <HeadFormulario step={this.state.step} aplica='1' />
                        <form onSubmit={this.procesarFiltro1}>
                            <InformacionCrediticiasForm
                                handleChangeCrediticios={this.handleChangeCrediticios}
                                formcrediticios={this.state.formcrediticios}
                                montomaximoerror={this.state.montomaximoerror}
                            />
                            <InformacionLaboralForm
                                handleChangeLaboral={this.handleChangeLaboral}
                                formdatoslaboral={this.state.formdatoslaboral}
                            />
                            <IngresosyEgresos
                                formingresos={this.state.formingresos}
                                handleChangeIngresos={this.handleChangeIngresos}
                                formegresos={this.state.formegresos}
                                handleChangeEngresos={this.handleChangeEngresos}
                                formdatoslaboral={this.state.formdatoslaboral}
                            />
                        </form>
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                        <SweetAlert2 {...this.state.swal} />
                        <HeadFormulario step={this.state.step} aplica='1' />
                        <JustificacionesForm
                            prevStep={this.prevStep}
                            handleChangeCrediticios={this.handleChangeCrediticios}
                            formcrediticios={this.state.formcrediticios}
                            handleChangeLaboral={this.handleChangeLaboral}
                            formdatoslaboral={this.state.formdatoslaboral}
                            formFile={this.state.formFile}
                            archivoExtension={this.state.archivoExtension}
                            formjustificacion={this.state.formjustificacion}
                            handleChangeJustificacion={this.handleChangeJustificacion}
                            archivosJustificacion={this.state.archivosJustificacion}
                            agregarJustificacion={this.agregarJustificacion}
                            eliminarFilaJustificacion={this.eliminarFilaJustificacion}
                            errorDescripcionVacio={this.state.errorDescripcionVacio}
                            errorArchivoVacio={this.state.errorArchivoVacio}
                            campovacio1={this.state.campovacio1}
                            procesarFiltro2={this.procesarFiltro2}
                            datasimulador={this.state.datasimulador}

                        />
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        <SweetAlert2 {...this.state.swal} />
                        <HeadFormulario step={this.state.step} aplica='1' />
                        <InformacionPersonaForm
                            formpersona={this.state.formpersona}
                            handleChangePersona={this.handleChangePersona}
                            prevStep={this.prevStep}
                            cantones={this.state.cantones}
                            parroquias={this.state.parroquias}
                            recintossector={this.state.recintossector}
                            handleChangeAutorizacion={this.handleChangeAutorizacion}
                            formautorizacion={this.state.formautorizacion}
                            guardarSolicitud={this.guardarSolicitud}
                            recopilarDATOS={this.recopilarDATOS}
                        />

                    </React.Fragment>
                )
            case 10:
                return (
                    <React.Fragment>
                        <SweetAlert2 {...this.state.swal} />
                        <HeadFormulario step={this.state.step} aplica='0' />
                        <ResultadoNoAplica
                            formcrediticios={this.state.formcrediticios}
                            valorcuotacalculada={this.state.valorcuotacalculada}
                            nuevamente={this.nuevamente}
                        />
                    </React.Fragment>
                )
            default: return null
        }
    }
}

export default Forms;