import React from "react";
import apiFuturoLamanense from "../../services/futurolamanenseApi";
import Modal from "react-responsive-modal";
import apiCreditoOnline from "../../services/creditoonlineApi";

class InformacionPersonaForm extends React.Component {

    state = {
        estadoscivil: [],
        paises: [],
        tipovivienda: [],
        tipoeducacion: [],
        profesiones: [],
        actividadeseconomicas: [],
        etnias: [],
        provincias: [],
        open: false,
        open2: false,
        cantones: []

    }

    componentDidMount() {
        this.getEstadoCivil();
        this.getPaises();
        this.getTipoEducacion();
        this.getProfesiones();
        this.getActividadesEconomicas();
        this.getEtnias();
        this.getTipoVivienda();
        this.getProvincias();
        this.getDataPersona();
    }

    getEstadoCivil = async () => {
        try {
            const data = await apiFuturoLamanense.persona.getestadocivil();
            this.setState({ estadoscivil: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getPaises = async () => {
        try {
            const data = await apiFuturoLamanense.generales.getpaises();
            this.setState({ paises: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getTipoEducacion = async () => {
        try {
            const data = await apiFuturoLamanense.persona.gettipoeducacion();
            this.setState({ tipoeducacion: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getProfesiones = async () => {
        try {
            const data = await apiFuturoLamanense.persona.getprofesiones();
            this.setState({ profesiones: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getActividadesEconomicas = async () => {
        try {
            const data = await apiFuturoLamanense.generales.getactividadeseconomicas();
            this.setState({ actividadeseconomicas: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getEtnias = async () => {
        try {
            const data = await apiFuturoLamanense.persona.getetnias();
            this.setState({ etnias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getTipoVivienda = async () => {
        try {
            const data = await apiFuturoLamanense.persona.gettipovivienda();
            this.setState({ tipovivienda: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    getProvincias = async () => {
        try {
            const data = await apiFuturoLamanense.generales.getprovincias();
            this.setState({ provincias: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal2 = () => {
        this.setState({ open2: false });
    };

    onOpenModal2 = () => {
        this.setState({ open2: true });
    };

    getDataPersona = async () => {
        let seEncuentraRegistradoEnMysql = sessionStorage.getItem('isregisterok');
        if (seEncuentraRegistradoEnMysql == 'false') {
            try {
                const data = await apiCreditoOnline.personas.getdataExistente(sessionStorage.getItem('identificacion'));
                if (data.response) {
                    if (data.response.secuencial != 0) {
                        this.props.recopilarDATOS(
                            data.response.codigoIndividualDactilar,
                            data.response.nombres,
                            data.response.apellidos,
                            data.response.esMasculino,
                            data.response.codigoEstadoCivil,
                            data.response.codigoPaisOrigen,
                            data.response.fechaNacimiento,
                            data.response.email,
                            data.response.CARGASFAMILIARES,
                            data.response.codigoTipoEducacion,
                            data.response.codigoProfesion,
                            data.response.codigoActividadEconomica,
                            data.response.codigoTipoEtnia,
                            data.response.numeroTelefono,
                            data.response.idprovincia,
                            data.response.idcanton,
                            data.response.idsector,
                            data.response.codigoRecinto,
                            data.response.codigoTipoVivienda,
                            data.response.direccionDomicilio,
                            data.response.referenciaDomiciliaria
                        )
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    getCantones = async (secuencialDivisionPadre) => {
        try {
            const data = await apiFuturoLamanense.generales.getcantones(secuencialDivisionPadre);
            this.setState({ cantones: data.response })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { open, open2 } = this.state;
        const { handleChangePersona, formpersona, cantones, parroquias, recintossector, formautorizacion, handleChangeAutorizacion, guardarSolicitud } = this.props;

        return (
            <React.Fragment>
                <form onSubmit={guardarSolicitud}>
                    <div className=" nav-ss p-2 mb-0">
                        <b >Información Personal</b>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="tipoidentificacion" value={formpersona.tipoidentificacion} required disabled="disabled">
                                    <option value="C">Cédula</option>
                                    <option value="R">RUC</option>
                                </select>
                                <label >Tipo de indentificación</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="text" className="form-control" onChange={handleChangePersona} name="identificacion" value={formpersona.identificacion} required disabled="disabled" />
                                <label htmlFor="floatingInput">Identificación</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="text" className="form-control" onChange={handleChangePersona} name="codigodactilar" value={formpersona.codigodactilar} required />
                                <label htmlFor="floatingInput">Código Dactilar</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="text" className="form-control" onChange={handleChangePersona} name="nombres" value={formpersona.nombres} required />
                                <label htmlFor="floatingInput">Nombres</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="text" className="form-control" onChange={handleChangePersona} name="apellidos" value={formpersona.apellidos} required />
                                <label htmlFor="floatingInput">Apellidos</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="sexo" value={formpersona.sexo} required >
                                    <option value="">Seleccione el sexo...</option>
                                    <option value="1">Masculino</option>
                                    <option value="0">Femenino</option>
                                </select>
                                <label >Sexo</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <select className="form-select" onChange={handleChangePersona} name="estadocivil" value={formpersona.estadocivil} required>
                                    <option value="" className="seleccion-inicio">Seleccione el estado civil...</option>
                                    {
                                        this.state.estadoscivil.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label htmlFor="floatingSelect">Estado civil</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <select className="form-select" onChange={handleChangePersona} name="paisnacimiento" value={formpersona.paisnacimiento} required>
                                    <option value="" className="seleccion-inicio">Seleccione el país de nacimiento...</option>
                                    {
                                        this.state.paises.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label htmlFor="floatingSelect">País de nacimeinto</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="date" className="form-control" onChange={handleChangePersona} name="fechanacimiento" value={formpersona.fechanacimiento} required />
                                <label htmlFor="floatingInput">Fecha de nacimiento</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="email" className="form-control" onChange={handleChangePersona} name="email" value={formpersona.email} required />
                                <label htmlFor="floatingInput">Correo electrónico</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="number" className="form-control" onChange={handleChangePersona} name="cargasfamiliares" value={formpersona.cargasfamiliares} />
                                <label htmlFor="floatingInput">Cargas familiares</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="tipoeducacion" value={formpersona.tipoeducacion} required >
                                    <option value="">Seleccione el tipo educación...</option>
                                    {
                                        this.state.tipoeducacion.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Tipo Educación</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="profesion" value={formpersona.profesion} required >
                                    <option value="">Seleccione la profesión...</option>
                                    {
                                        this.state.profesiones.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Profesión</label>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="actividadeconomica" value={formpersona.actividadeconomica} required >
                                    <option value="">Seleccione la actividad económica...</option>
                                    {
                                        this.state.actividadeseconomicas.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Actividad económica</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="etnia" value={formpersona.etnia} required >
                                    <option value="">Seleccione el tipo de etnia...</option>
                                    {
                                        this.state.etnias.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Tipo Etnia</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating ">
                                <input type="text" className="form-control" onChange={handleChangePersona} name="celular" value={formpersona.celular} required />
                                <label htmlFor="floatingInput">Celular</label>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className=" nav-ss p-2 mb-0">
                        <b >Información de domicilio</b>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="idprovincia" value={formpersona.idprovincia} required >
                                    <option value="">Seleccione la provincia...</option>
                                    {
                                        this.state.provincias.map((data, index) => {
                                            return (
                                                <option value={data.secuencial} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Provincia de domicilio</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="idcanton" value={formpersona.idcanton} required >
                                    <option value="">Seleccione el cantón...</option>
                                    {
                                        cantones.map((data, index) => {
                                            return (
                                                <option value={data.secuencial} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Cantón de domicilio</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="idsector" value={formpersona.idsector} required >
                                    <option value="">Seleccione la parroquia...</option>
                                    {
                                        parroquias.map((data, index) => {
                                            return (
                                                <option value={data.secuencial} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Parroquias</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating">
                                <select className="form-select" id="floatingSelect" onChange={handleChangePersona} name="codigorecinto" value={formpersona.codigorecinto} required >
                                    <option value="">Seleccione el recinto / sector...</option>
                                    {
                                        recintossector.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label >Recinto / Sector</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-floating mb-3">
                                <select className="form-select" onChange={handleChangePersona} name="tipovivienda" value={formpersona.tipovivienda} required>
                                    <option value="" className="seleccion-inicio">Seleccione tipo vivienda...</option>
                                    {

                                        this.state.tipovivienda.map((data, index) => {
                                            return (
                                                <option value={data.codigo} key={index + 1} >
                                                    {data.nombre}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <label htmlFor="floatingSelect">Tipo de vivienda</label>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea" onChange={handleChangePersona} name="direcciondomicilio" value={formpersona.direcciondomicilio} required></textarea>
                                <label htmlFor="floatingTextarea">Dirección domicilio</label>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea" onChange={handleChangePersona} name="referenciadomicilio" value={formpersona.referenciadomicilio}></textarea>
                                <label htmlFor="floatingTextarea">Referencia domicilio</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" name="check2" value={formautorizacion.check2} onChange={handleChangeAutorizacion} onClick={() => this.onOpenModal2()} required />
                                <label className="form-check-label text-autorizacion" htmlFor="flexCheckDefault">
                                    Autorización para la recopilación de datos personales y crediticios, de cualquier fuente de información o de empresas que oferten servicios de información estadística
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" name="check1" value={formautorizacion.check1} onChange={handleChangeAutorizacion} onClick={() => this.onOpenModal()} required />
                                <label className="form-check-label text-autorizacion" htmlFor="flexCheckDefault">
                                    Autorización para el tratamiento de datos personales
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <div className="text-end">
                            <button className='buttons__button buttons__button--back' onClick={this.back}>Atrás</button>
                            <button className="btnsiguiente" >
                                Finalizar
                            </button>
                        </div>
                    </div>

                </form>


                <Modal open={open} onClose={this.onCloseModal} center w
                    classNames={{
                        overlayAnimationIn: 'customEnterOverlayAnimation',
                        overlayAnimationOut: 'customLeaveOverlayAnimation',
                        modalAnimationIn: 'customEnterModalAnimation',
                        modalAnimationOut: 'customLeaveModalAnimation',
                        modal: 'modalws'
                    }}
                    animationDuration={800}>
                    <h4><i className="far fa-address-card"></i> Autorización para el tratamiento de datos personales</h4>
                    <div className="container text-justify w-100 border-top pt-2">
                        <p>
                            Por medio del presente documento, autorizo de manera libre y voluntaria a COAC FUTURO LAMANENSE para que
                            conforme los términos establecidos legalmente se realice el tratamiento de mis datos personales, como la
                            recolección, intercambio, actualización, procesamiento, reproducción, compilación, almacenamiento,
                            circulación, sistematización de mi información personal que entrego o entregaré y en general la organización
                            de mis datos personales de forma parcial o total, en virtud de las relaciones comerciales, contractuales,
                            legales y/o de cualquier otra que surja en relación a los servicios o productos financieros y no financieros
                            que brinda esta Institución.
                        </p>
                        <p>
                            Para lo cual el tratamiento de DATOS tendrá las siguientes finalidades: elaborar y/o segmentar perfiles;
                            realizar análisis crediticios incluso mediante técnicas automáticas; desarrollar acciones comerciales de
                            productos y servicios financieros y/o comerciales; y/o, comercializar y promocionar (incluyendo el envío de
                            publicidad por cualquier medio) diferentes productos y servicios financieros y/o comerciales.
                        </p>
                        <p>
                            Entiendo que la información será utilizada directa o indirectamente para procesos pre-contractuales,
                            contractuales, pos-contractuales, comerciales, de atención al cliente y mercadeo, procesamiento, análisis,
                            investigación, estadísticas, referencias y demás trámites operativos para facilitar, promover, permitir o
                            mantener las relaciones con la Cooperativa, de igual forma autorizo a la Cooperativa o a cualquier
                            cesionario o endosatario para que, cuantas veces sean necesarias, obtenga de y/o valide con cualquier fuente
                            de información, incluyendo al Registro Civil, la Central de Riesgos, los burós de información crediticia, y
                            demás fuentes legales de información autorizados para operar en el país, las personas naturales y los
                            establecimientos de comercio, información sobre mi empleador, personas señaladas como referencias,
                            instituciones financieras de crédito, de cobranza, compañías emisoras o administradoras de tarjetas de
                            crédito y cualquier otra entidad, mis referencias personales y/o patrimoniales del pasado, del presente y
                            las que se generen en el futuro, sea como deudor principal, codeudor o garante, así como sobre mi
                            comportamiento crediticio, manejo de cuentas bancarias, tarjetas de crédito y, en general, sobre el
                            cumplimiento de mis obligaciones.
                        </p>
                        <p>
                            Faculto formalmente a la Cooperativa para transferir o entregar a las mismas personas o entidades la
                            información relacionada con mi comportamiento crediticio. En caso de que la Cooperativa ceda o transfiera
                            cartera adeudada por mí, el cesionario o adquiriente de la misma queda desde ahora expresamente facultado
                            para realizar las mismas actividades establecidas en el párrafo anterior.
                        </p>
                        <p>
                            Autorizo a que la COAC FUTURO LAMANENSE utilice mis datos para contactarme con fines informativos,
                            publicitarios y comerciales a través del servicio de telefonía o cualquier otro medio de comunicación
                            electrónica.
                        </p>
                        <p>
                            Adicionalmente, autorizo también a que la COAC FUTURO LAMANENSE dé tratamiento a mis datos personales a
                            través de terceros, para los fines arriba detallados, con cualquier persona natural o jurídica de derecho
                            público, privado, nacional o extranjera que la Cooperativa determine.
                        </p>
                        <p>
                            Entiendo y acepto que mi información personal podrá ser almacenada de manera impresa o digital, y será
                            accesada por los funcionarios de la COAC FUTURO LAMANENSE, estando obligados a cumplir con la legislación
                            aplicable a las políticas de confidencialidad, protección de datos y sigilo bancario. La COAC FUTURO
                            LAMANENSE conservará la información personal al menos durante el tiempo que dure la relación comercial y el
                            que sea necesario para cumplir con la normativa, respecto de la conservación de archivos.
                        </p>
                        <p>
                            Así mismo, declaro que conozco que la recolección y tratamiento de mis datos se realizará de conformidad con
                            normas legales expresas sobre protección de datos personales que resulten aplicables.
                        </p>
                        <p>
                            Manifiesto que, como titular de los datos, fui informado de los derechos con que cuento para conocer,
                            actualizar y rectificar mi información personal o revocar la autorización, lo cual podré ejercerlo a través
                            de un documento por escrito acudiendo a cualquiera de las agencias de la Cooperativa.
                        </p>
                        <p>
                            Por medio de la lectura de este documento manifiesto que he sido informado sobre el Tratamiento de Datos
                            Personales, y otorgo mi autorización de forma voluntaria y verídica, y en señal de aceptación suscribo el
                            presente documento.
                        </p>
                        <div className="border-top pt-2 text-end">
                            <button className="btn btn-secondary" onClick={() => this.onCloseModal()}>
                                <i className="far fa-check-square"></i> ACEPTO
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal open={open2} onClose={this.onCloseModal2} center w
                    classNames={{
                        overlayAnimationIn: 'customEnterOverlayAnimation',
                        overlayAnimationOut: 'customLeaveOverlayAnimation',
                        modalAnimationIn: 'customEnterModalAnimation',
                        modalAnimationOut: 'customLeaveModalAnimation',
                        modal: 'modalws'
                    }}
                    animationDuration={800}>
                    <h5 ><i className="fas fa-address-card"></i> Autorización para la recopilación de datos personales y crediticios</h5>
                    <div className="container text-justify w-100 border-top pt-2 ">
                        <p>
                            Autorizo de forma expresa, libre y voluntaria a la Cooperativa de Ahorro y Crédito “Futuro Lamanense”, o a cualquier beneficiario o endosatario, para que obtenga en cualquier momento: Información personal, crediticia, incluido el comportamiento e historial crediticio, sociodemográfica, económica y de contactabilidad, o para valorar el riesgo futuro de concederme cualquier producto o servicio de cualquier fuente de información o de empresas que oferten servicios de información estadística sin que la enunciación efectuada limite la autorización concedida.
                        </p>
                        <p>
                            Declaro que he sido informado sobre la naturaleza y alcance de la presente autorización, así como el uso que la cooperativa dará a la información a la cual acceda, razón por la cual exonero toda responsabilidad a la antes citada entidad, respecto a reclamos en general que podría tener lugar en atención a esta autorización.
                        </p>
                        <p>
                            Esta <b>AUTORIZACIÓN</b> será suficiente para que la Cooperativa de Ahorro y Crédito “Futuro Lamanense”, obtenga la información que requiera en calidad de prestatario o garante, por lo tanto, manifiesto haber recibido, leído y entendido el contenido del presente documento.
                        </p>

                        <div className="border-top pt-2 text-end">
                            <button className="btn btn-secondary" onClick={() => this.onCloseModal2()}>
                                <i className="far fa-check-square"></i> ACEPTO
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export default InformacionPersonaForm;