import React from "react";
import { Link } from "react-router-dom";

class ResultadoNoAplica extends React.Component {



    render() {
        const { formcrediticios, valorcuotacalculada, nuevamente } = this.props;
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center text-dark card-fondo rounded pt-3">
                            <br />
                            <i className="far fa-frown ico-triste"></i>
                            <h5 className="mt-3">Estimado Cliente</h5>
                            <h5><b>NO TENEMOS UNA OFERTA PARA TI</b></h5>
                            <p><b>PUEDES INTENTAR NUEVAMENTE CON UN MONTO MENOR AL QUE SOLICITASTE</b></p>
                            <label className="border-end pe-3 ps-3 border-top border-start border-bottom pt-1 pb-1 border-dark">Monto solicitado: ${formcrediticios.monto}</label>
                            <label className="border-end pe-3 ps-3 border-top border-bottom pt-1 pb-1 border-dark">plazo: {formcrediticios.plazomeses}</label>
                            <label className="border-end pe-3 ps-3 border-top border-bottom pt-1 pb-1 border-dark">Valor cuota: ${valorcuotacalculada}</label>
                            <br /> <br /> <br />
                            <Link to={'/'} className="btn-salir me-4" >
                                Salir
                            </Link>
                            <button className="btnsiguiente" onClick={nuevamente}>
                                Intentar nuevamente
                            </button>
                            <br /> <br /> <br />
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default ResultadoNoAplica;